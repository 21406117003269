import { Directive } from '@angular/core';
import { Utils } from '@services/utils';
import { QuickBooksService } from '@services/quickbooks.service';
import { BaseFormItem } from '@app/admin/base/form-item';
import { BehaviorSubject } from 'rxjs';

@Directive()
export abstract class BaseQuickbooksComponent extends BaseFormItem {

  private apiReadySubject = new BehaviorSubject<boolean>(null);
  apiReady$ = this.apiReadySubject.asObservable();

  quickbookService: QuickBooksService
  isLoadingService = true;
  timer = null;
  popup: Window;
  errorAuthMsg;

  constructor() {
    super();
    this.quickbookService = new QuickBooksService(this.api);
  }

  ngOnInit() {
    super.ngOnInit();
    this.isLoadingService = true;
    this.quickbookService.checkAPIReady().then(res => {
      this.errorAuthMsg = null;
      this.isLoadingService = false;
      this.apiReadySubject.next(true);
    }).catch(err => {
      this.connectToQuickbooks();
    })
  }

  protected connectToQuickbooks() {
    this.isLoadingService = true;
    this.quickbookService.connectToQuickbooks().then(res => {
      if (this.popup) { this.popup.close(); }
      this.popup = window.open(res, '_blank', "height=600,width=600");
      
      this.timer = setInterval(() => {
        if (!this.popup.closed) {
          try {
            const url = this.popup.document?.URL;
            if (url && url.includes('qbo-oauth-success')) {
              this.errorAuthMsg = null;
              this.popup.close();
              this.isLoadingService = false;
              this.apiReadySubject.next(true);
              clearInterval(this.timer);
            }
          } catch (err) {}
        } else {
          clearInterval(this.timer);
          this.errorAuthMsg = 'There was an error during the process. Please try again.';
          this.isLoadingService = false;
        }
      }, 1000); // Run every 1 second

    }).catch(err => {
      this.showErr(err);
      this.errorAuthMsg = Utils.getErrorString(err);
      this.isLoadingService = false;
    });
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.popup) { this.popup.close(); }
    super.ngOnDestroy();
  }

  protected handleErrorQueryQuickbooks(err) {
    if (err?.data?.moduleErrorCode === 401) {
      this.errorAuthMsg = 'There was an error during the process. Please try again.';
      this.showErr(err);
    } else {
      this.showErr(err);
    }
  }

  public onBtnConnectToQuickbooks() {
    this.connectToQuickbooks();
  }
  
}
