import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { DisplayFinJobCost } from '.';

@NgModule({
  imports: [
    CommonModule,
    NzTableModule,
  ],
  declarations: [DisplayFinJobCost],
  exports: [DisplayFinJobCost]
})
export class ModuleDisplayFinJobCost {}