import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from "ng-zorro-antd/input";
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { SearchQuickbooksVendor } from './search-quickbooks-vendor';
import { CreateQuickbooksVendor } from './create-vendor';
import { CreateQuickbooksBill } from './create-bill';
import { QuickbooksBillCreateSuccessfully } from './success-dialog';
import { SharedModule } from '@app/admin/shared/shared.module';
import { ModuleSearchQBBill } from './input-search-bill/module';
import { CreateQuickbooksBill_V2 } from './create-bill-v2';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzModalModule,
    NzTableModule,
    NzCheckboxModule,
    NzDescriptionsModule,
    NzPopoverModule,
    NzRadioModule,
    NzSpinModule,
    NzToolTipModule,
    NzDividerModule,
    NzDropDownModule,
    NzIconModule,
    NzSelectModule,
    NzButtonModule,
    NzDatePickerModule,
    NzInputModule,
    NzPopconfirmModule,
    SharedModule,
    ModuleSearchQBBill,
  ],
  declarations: [
    SearchQuickbooksVendor,
    CreateQuickbooksVendor,
    CreateQuickbooksBill,
    CreateQuickbooksBill_V2,
    QuickbooksBillCreateSuccessfully,
  ],
  exports: [
  ]
})
export class ModuleFinQuickbooks {}
