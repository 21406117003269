import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { FormArray } from "@angular/forms";
import { FormUtil } from "@services/form-util";

const PaymentServices = {
  Denim: 'Denim',
}

@Component({
  selector: 'create-fin-statement',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class CreateFinStatement extends BaseFormDialog1 {

  public displayInfo: any = {}
  private static _comeFromScreen = 'receivableFinance';
  public uploadImages = [];
  @Input() set comeFromScreen(value) {
    CreateFinStatement._comeFromScreen = value;
  }

  @Input() onRefresh: () => void;

  public listOfPaymentService = Object.values(PaymentServices);

  protected formGroupDeclaration: FormGroupDeclaration = {
    batch: { label: '', type: 'formGroup', childItem: {
      id: { label: '', required: true },
      code: { label: 'Code'},
    }},
    isIncludingRelatedBatches: { label: 'Including related batches', type: 'boolean', initialValue: true },
    proofOfPaymentFiles:  { label: 'Proof Of Payment', required: false },
    paymentServiceName: { label: 'Payment Service', required: true },
    paymentServiceDenim: { label: '', type: 'formGroup', childItem: {
      refs: { label: 'Denim Reference', type: 'formArray', childItem: {
        label: '', notAcceptEmpty: true
      }, initialValue: ['']},
    }},
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.autoSelectPaymentServiceIfNeed();
    this.onCheckboxChanged(this.getItemValue('isIncludingRelatedBatches'));
  }

  get isCreateNew(): boolean {
    return true;
  }

  protected afterBindModel(model): void {
    super.afterBindModel(model);
    this.buildDisplayInfo(model);
  }

  get isComeFromReceivable() {
    return CreateFinStatement._comeFromScreen === 'receivableFinance'
  }

  get isComeFromPayable() {
    return CreateFinStatement._comeFromScreen === 'payableFinance'
  }

  private buildDisplayInfo(model: any) {
    this.displayInfo = {
      batchCode: this.getBatchCode(model),
      bizCode: model?.relatedBiz?.code ?? 'N/A',
      accountName: model?.finAccount?.name ?? 'N/A',
      cost: Utils.isNumber(model?.cost) ? '$' + InputHelper._formatMoney(model.cost, 0) : 'N/A',
      invoiceFiles: model?.invoiceFiles ?? [],
    }
  }

  private getBatchCode(model) {
    const code = model?.batch?.code ?? '';
    if (!code) return '';
    if (this.isComeFromReceivable) {
      return `AR-${code}`;
    } else if (this.isComeFromPayable) {
      return `AP-${code}`;
    }
  }

  showRelatedBatchCode(code) {
    if (this.isComeFromReceivable) {
      return `AP-${code}`;
    } else if (this.isComeFromPayable) {
      return `AR-${code}`;
    }
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    const json: any = super.getFormData_JSON(true);
    const batchId = json.batch.id;
    if (!batchId) return;
    if (!this.model?.cost) {
      this.showErr('Can not create statement with cost is zero.');
      return;
    }
    if (this.isComeFromReceivable && !this.model?.invoiceFiles?.length) {
      this.showErr('Not found invoice order. Please generate invoice first.');
      return;
    }
    const isIncludingRelatedBatches = !!json.isIncludingRelatedBatches;
    let paymentServiceInfo: any = {};
    if (json.paymentServiceName === PaymentServices.Denim) {
      paymentServiceInfo = {
        name: PaymentServices.Denim,
        refs: json.paymentServiceDenim.refs?.filter(x => x),
      }
    }
    return { 
      finBatchIds: [ batchId ],
      isIncludingRelatedBatches,
      paymentServiceInfo,
    }
  }

  public onBtnSave() {
    const data = this.getFormData_JSON(true);
    if (!data) return;
    const url = Const.APIURI_FINANCES_STATEMENT();
    this.startProgress();
    if (!this.uploadImages.length) {
      this.api.POST(url, data).subscribe(
        (resp) => {
          this.showSuccess(resp);
          this.stopProgress();
          this.closeDialog();
          this.onRefresh();
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
        }
      );
    } else {
      let formData = new FormData();
      formData.append("params", JSON.stringify(data));
      for (let i=0; i < this.uploadImages.length; i++) {
        formData.append(`proofOfPaymentImages.${i}`, this.uploadImages[i].file, this.uploadImages[i].fileName);
      }
      this.api.postFormData(url, formData).subscribe(
        (resp) => {
          this.showSuccess(resp);
          this.stopProgress();
          this.closeDialog();
          this.onRefresh();
        },
        (err) => {
          this.showErr(err);
          this.stopProgress();
        }
      );
    }
  }

  private getDataRelatedBatched() {
    const batchId = this.getItemValue('batch.id');
    const url = Const.APIURI_FINANCES_BATCH(`${batchId}/related_batches`);
    this.api.GET(url).subscribe(
      resp => {
        this.relatedBatches = resp.data.list_data;
      }, err => {
      }
    );
  }

  get isShowRelatedBatches() {
    return !!this.getItemValue('isIncludingRelatedBatches');
  }

  public relatedBatches: any[] = [];
  onCheckboxChanged(event) {
    if (event && !this.relatedBatches?.length) {
      this.getDataRelatedBatched();
    }
  }

  private autoSelectPaymentServiceIfNeed() {
    if (this.formInput && this.listOfPaymentService.length === 1) {
      this.setItemValue('paymentServiceName', this.listOfPaymentService[0]);
    }
  }

  get isSelectdPaymentServiceDenim() {
    return this.getItemValue('paymentServiceName') === PaymentServices.Denim;
  }

  onBtnAddRef() {
    let fa = <FormArray>this.formInput.get('paymentServiceDenim').get('refs');
    let declaration = this.formGroupDeclaration['paymentServiceDenim'].childItem['refs'];
    let f = FormUtil.createChildItem(declaration, '');
    fa.push(f);
  }

  get shouldShowRemoveDenimReference() {
    let fa = <FormArray>this.formInput.get('paymentServiceDenim').get('refs');
    return fa?.length && fa.length > 1;
  }

  onBtnRemoveDenimReference(index) {
    let fa = <FormArray>this.formInput.get('paymentServiceDenim').get('refs');
    fa.removeAt(index);
  }

  onFileImageSelectedChange(files) {
    this.uploadImages = files;
  }

}
