<div>
  <div style="margin-bottom: 15px; font-size: 16px;">By adding issue here, all sale reps will get notification about it.</div>

  <nz-checkbox-group class="list-emails" [(ngModel)]="listData"></nz-checkbox-group>

  <div style="margin-top: 15px; font-style: italic;">You can also add more emails to send notification.</div>
  <nz-input-group nzSearch [nzAddOnAfter]="suffixButton">
    <input type="text" nz-input placeholder="Additional email will get notification" [(ngModel)]="moreEmail" (ngModelChange)="onMoreEmailChange()"/>
  </nz-input-group>
  <ng-template #suffixButton>
    <button nz-button (click)="addMoreEmail()" [disabled]="!moreEmail"><i nz-icon nzType="plus"></i></button>
  </ng-template>
  <div class="email-error">{{moreEmailError}}</div>

  <div style="margin-top: 15px; font-size: 16px;">Are you sure you want to continue?</div>

</div>