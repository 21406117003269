import {Component, EventEmitter, Input, NgZone, Output} from '@angular/core';
import {BaseFormItem} from '@app/admin/base/form-item';
import {ActivatedRoute} from '@angular/router';
import {Utils} from '@services/utils';
import {Const} from '@const/Const';
import {MasterData} from '@services/master.data';
import {Log} from "@services/log";
import {Subscription} from "rxjs";
import { DateUtil } from "@services/date-utils";
import { ExtendValidators } from '@app/admin/base/validator';

@Component({
  selector: '[dat-truck-filter]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../../../../app.scss', '../../../../dialogs/dialogs.scss']
})
export class DatTruckFilter extends BaseFormItem {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  @Input() equipmentTypes: any = [];
  @Input() loadTypes: any = [];

  public sort: any = undefined;
  public listAddressAutoComplete: any = {};
  private subAddressSuggestion: Subscription;
  private timerAddressSuggestion;
  public loaded: number = 0;
  isValidFormInput = false;
  public page: number = 1;

  datCompanies = [];
  equipmentOptions = [];

  public get declaration(): FormGroupDeclaration {
    return {
      origin: {label: 'Origin', placeHolder: 'Select', required: true},
      destination: {label: 'Destination', placeHolder: 'Select', required: true},
      dho: {label: 'DH-O', placeHolder: '', initialValue: 150},
      dhd: {label: 'DH-D', notAcceptEmpty: true, placeHolder: '', initialValue: 150},
      equipmentType: {label: 'Equipment Type', placeHolder: 'Select', required: true},
      loadType: {label: 'Load Type', placeHolder: 'Select', initialValue: "BOTH", required: true},
      lengthFeet: {label: 'Length (fts)', placeHolder: 'Feets'},
      weightPounds: {label: 'Weight (lbs)', placeHolder: 'Pounds'},
      includeOnlyTrackingRequired: {label: '', type: "boolean", initialValue: false},
      maxAgeHours: {label: '', type: "number", initialValue: 24},
      isExcludeCompany: {label: '', type: "boolean", notAcceptEmpty: true},
      companyName: {label: '', placeHolder: 'Company Name', notAcceptEmpty: true},
      dateRange: {label: '', type: "array", required: true, initialValue: [new Date(), new Date()], getValue: (val) => [DateUtil.convertLocalTime(val[0], DateUtil.getLocalTimeZone()), DateUtil.convertLocalTime(val[1], DateUtil.getLocalTimeZone())], validators: ExtendValidators.validateTimeWindow}
    };
  };

  protected formGroupDeclaration: FormGroupDeclaration = this.declaration;

  constructor() {
    super();
    this.onRefresh();
  }

  ngOnChanges(): void {
    super.ngOnChanges();
    this.equipmentOptions = this.equipmentTypes.map(it => {
      return {label: it.label, value: it.value, groupLabel: it.group === "classes" ? "Classes" : "Types"};
    });
  }

  onRefresh() {
    this.onReload.emit();
  }

  onChange(event, key) {
    this.page = 1;
  }

  doSearch() {
    this.onSearch.emit(this.formData_JSON(true));
  }

  clearSearch(keys) {
    keys.forEach(key => {
      this.setItemValue(key, this.formGroupDeclaration[key].initialValue);
    });
    if (this.formGroupError) return;
    this.doSearch();
  }

  onSwap() {
    const origin = this.getItemValue('origin');
    const destination = this.getItemValue('destination');
    this.setItemValue('destination', origin);
    this.setItemValue('origin', destination);
  }

  private tryAddressSuggestion(elm: HTMLInputElement) {
    clearTimeout(this.timerAddressSuggestion);
    let userInput = elm.value;
    if (!userInput) {
      this.subAddressSuggestion?.unsubscribe();
      this.listAddressAutoComplete[elm.id] = [];
      return;
    }
    this.timerAddressSuggestion = setTimeout(() => this.doAddressSuggestion(elm, userInput), 100);
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'origin':
      case 'destination':
        return this.tryAddressSuggestion(event.target);
      default:
        return super.onInputChanged(event, key);
    }
  }

  private doAddressSuggestion(elm: HTMLInputElement, userInput: string) {
    this.subAddressSuggestion?.unsubscribe();

    this.subAddressSuggestion = this.api.POST(`${Const.APIURI_THIRD_PARTY_POST}/search/location`, {query: userInput}).subscribe(
      resp => {
        this.listAddressAutoComplete[elm.id] = resp.data.list_data;
      }, err => {
        Log.e('doAddressSuggestion failed. ', err);
      }
    );
    // this.subAddressSuggestion = this.api.searchUsCities(userInput).subscribe(
    //   resp => {
    //     this.listAddressAutoComplete[elm.id] = resp.data.list_data.map(it => `${it.city}, ${it.stateCode}`);
    //   }, err => {
    //     Log.e('doAddressSuggestion failed. ', err);
    //   }
    // );
  }

  compareAddress(option, value): boolean {
    if (typeof option === 'string') {
      return option.split('-')[0].trim() === value.code;
    }
    if (option.code) return option.code === value.code;
    return option.id === value.id;
  }

  getLocationName(location) {
    if (location.code) {
      if (location.name) return `${location.code} - ${location.name}`;
      if (location.short) return `${location.code} - ${location.short}`;
    }
    return location.name;
  }

  disabledDate = (current: Date): boolean => {
    return current && current.getTime() < Date.now();
  }
}
