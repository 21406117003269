<div class="dashboard-child-container no-padding list">
  <div class="list-header flex no-padding" style="flex-direction: column; width: 100%;background-color: unset">
    <div (onFilterTabChanged)="onFilterTabChanged($event)" fin-receivable-batch-list-filter [countSum]="countSum" style="width: 100%;"></div>
  </div>
  <div class="list-body">
    <div class="flex flex-direction-reverse">
      <div class="bottom20"><span class="text-red">Total: {{totalCount}} {{ totalCount > 1 ? 'items' : 'item'}}</span></div>
    </div>
    <nz-table #nzTable [nzData]="listData"
      [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit" [nzSize]="'default'"
      nzBordered="true" nzSize="small"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata">
          <i nz-icon nzTheme="outline" nzType="search"></i>
          No data
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>Batch</th>
          <th>
            PU-DO Date & Ref
          </th>
          <th
            [nzSortOrder]="null"
            [nzSortFn]="sortRate"
            [nzSortDirections]="sortDirection"
          >
            Rate
          </th>
          <th>Status</th>
          <th
            [nzSortOrder]="null"
            [nzSortFn]="sortPayer"
            [nzSortDirections]="sortDirection"
          >
            Payer
          </th>
          <th
            [nzSortOrder]="null"
            [nzSortFn]="sortCarriers"
            [nzSortDirections]="sortDirection"
          >
            Carrier
          </th>
          <!-- <th>Revenue</th> -->
          <!-- <th>Payment term</th> -->
          <th>Doc status</th>
          <th>Actions</th>
          <th *ngIf="isAlreadyProcessed">Denim status</th>
          <!-- <th>Carrier invoice</th>
          <th *ngIf="isTabAlreadyPaid">Payment when/by</th>
          <th *ngIf="isTabAlreadyPaid">Note</th>
          <th *ngIf="shouldShowAction">Action</th> -->
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of nzTable.data; let i = index">
          <tr>
            <td>
              <div>
                <a [routerLink]="getRouterLinkDetail(item)" class="single-line"><code>{{showBatchCodeAR(item)}}</code></a>
              </div>
              <div class="top5">
                <a class="link" [routerLink]="[routeAdminOrderList, item.relatedBiz.id]" nz-tooltip
                  nzTooltipTitle="Go to order detail" style="font-size: 13px;" target="_blank">
                  <code>{{item.relatedBiz.code}}</code>
                </a>
                <a class="link" [routerLink]="[routeAdminDispatchList, item.relatedBiz?.metadata?.jobIds?.[0]]" target="_blank" nz-tooltip
                  nzTooltipTitle="Go to dispatch"><i nz-icon nzType="rocket" nzTheme="outline" style="margin-left: 5px;"></i></a>
              </div>
            </td>
            <!-- order ref number -->
            <td>
              <div class="flex">
                <div class="circle-pick"></div>
                <div class="left5">
                  <div class="single-line">{{ item.displayInfo?.pickWindow || 'N/A' }}</div>
                  <div *ngIf="item.displayInfo?.pickRefNums?.length" class="flex top5">
                    <div class="refNums">Ref:</div>
                    <div class="left5">
                      <div *ngFor="let ref of item.displayInfo.pickRefNums" class="order-ref">
                        <nz-tag class="ref-num" nzColor="default" [nzTooltipTitle]="ref" nzTooltipPlacement="bottom" nz-tooltip>{{ref}}</nz-tag>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex top5">
                <div class="circle-drop"></div>
                <div class="left5">
                  <div class="single-line">{{ item.displayInfo?.dropWindow || 'N/A' }}</div>
                  <div *ngIf="item.displayInfo?.dropRefNums?.length" class="flex top5">
                    <div class="refNums">Ref:</div>
                    <div class="left5">
                      <div *ngFor="let ref of item.displayInfo.dropRefNums" class="order-ref">
                        <nz-tag class="ref-num" nzColor="default" [nzTooltipTitle]="ref" nzTooltipPlacement="bottom" nz-tooltip>{{ref}}</nz-tag>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- Rate -->
            <td width="200">
              <div class="flex-space-between">
                <div>
                  <img *ngIf="isAlreadyProcessed" src="/assets/img/check.svg" alt="" class="right5">
                  Revenue
                </div>
                <div>{{formatMoney(item.relatedBiz.metadata?.customerRate || 0)}}</div>
              </div>
              <div class="flex-space-between top10">
                <div>
                  <img *ngIf="isAlreadyProcessed" src="/assets/img/check.svg" alt="" class="right5">
                  Carrier rate
                </div>
                <div>{{formatMoney(item.relatedBiz.metadata?.carrierRate || 0)}}</div>
              </div>
              <div class="top5 divider"></div>
              <div class="flex-space-between top10 medium">
                <div>
                  <img *ngIf="isAlreadyProcessed" src="/assets/img/check.svg" alt="" class="right5">
                  Margin
                </div>
                <div>{{formatMoney(item.relatedBiz.metadata?.margin || 0)}}</div>
              </div>
            </td>
            <td><div class="fin-status {{item.intermediateStatus}}">{{batchStatus(item)}}</div></td>
            <td>
              <div><a class="link" target="_blank" [routerLink]="[routeAdminClientList, item.account.id]">{{item.account.name}}</a></div>
              <div *ngIf="isAlreadyProcessed" class="top5">
                <div *ngFor="let invoiceSent of getinvoiceSentInfo(item), let i=index" class="flex">
                  <div nz-checkbox [(ngModel)]="invoiceSent.isInvoiceSent" [nzDisabled]="!canToggeInvoiceSent(item, i)"></div>
                  <div *ngIf="invoiceSent?.when" class="grey left5" style="font-size: 12px; font-style: italic">
                    <div class="single-line">Invoiced {{displayDateDB(invoiceSent?.when)}}</div>
                    <div class="single-line">by {{getFullName(invoiceSent?.byUser)}}</div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div *ngFor="let c of getCarriers(item)">
                <a class="link" target="_blank" [routerLink]="[routeAdminCarrierList, c.id]">{{c?.basicInfo?.name}}</a>
              </div>
              <div *ngIf="isAlreadyProcessed" class="top5">
                <div *ngFor="let carrierPaid of getCarrierPaidInfo(item), let i=index" class="flex">
                  <div nz-checkbox [(ngModel)]="carrierPaid.isPaid" [nzDisabled]="!canToggeCarrierPaid(item, i)"
                    nz-tooltip
                    [nzTooltipTitle]="getTooltipTitleCarrierPaid(carrierPaid)"
                    ></div>
                  <div *ngIf="carrierPaid" class="grey left5" style="font-size: 12px; font-style: italic">
                    <div class="single-line" *ngIf="carrierPaid?.when">Already paid {{displayDateDB(carrierPaid?.when)}}</div>
                    <div class="single-line" *ngIf="carrierPaid?.byUser">by {{getFullName(carrierPaid?.byUser)}}</div>
                    <div *ngIf="!isLoadingDenimStatus && !item.denimJobStatus && carrierPaid?.note">
                      {{ carrierPaid.note }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- <td>
              {{getAmountValue(item)}}
            </td> -->
            <!-- <td>
              {{getPaymentTermName(item.account.paymentTerm)}}
            </td> -->
            <!-- Doc status -->
            <td>
              <ng-container *ngIf="item.invoiceFiles?.length">
                <a (click)="onOpenConfirmPod(item)" class="left20">POD</a>
                <div class="flex" style="align-items: start;">
                  <img src="/assets/img/check.svg" alt="" class="right5 top5">
                  <div>
                    <div *ngFor="let invoice of item.invoiceFiles; let i = index">
                      <a (click)="downloadAttachedFile(invoice)">
                        <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                          class="right10"></i>Invoice {{ i > 0 ? (i+1) : '' }}
                      </a>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="!item.invoiceFiles?.length" class="flex gap5">
                <img src="/assets/img/circle-none.svg" alt="">
                <a (click)="onOpenConfirmPod(item)">Generate Invoice</a>
              </div>
              <ng-container *ngIf="(isReadyToProcess || isAlreadyProcessed) && !item.statement?.id">
                <a *ngIf="item.invoiceFiles?.length && !isClientStatementBill(item)" (click)="onBtnCreateStatement(item)">
                  <img src="/assets/img/circle-none.svg" alt="">
                  Create statement
                </a>
                <div *ngIf="!item.invoiceFiles?.length || isClientStatementBill(item)" class="center-vertical gap5">
                  <img src="/assets/img/circle-none.svg" alt="">
                  <span class="disable-link">Create statement</span>
                </div>
              </ng-container>
              <a *ngIf="(isReadyToProcess || isAlreadyProcessed) && item.statement?.id" (click)="onBtnViewStatement(item)">
                <img src="/assets/img/check.svg" alt="">
                {{ getLabelViewStatement(item) }}
              </a>
            </td>
            <td>
              <div *ngIf="!item.statement?.id" class="disable-link">Sync to Denim</div>
              <a *ngIf="item.statement?.id" (click)="onBtnViewStatement(item)">Sync to Denim</a>
            </td>
            <td *ngIf="isAlreadyProcessed">
              <nz-spin *ngIf="isLoadingDenimStatus" nzSimple></nz-spin>
              <div *ngIf="!isLoadingDenimStatus">
                <div class="bottom5">
                  <nz-tag [nzColor]="getColorTagStatus(item.denimJobStatus)">{{ getLabelDenimStatus(item.denimJobStatus) }}</nz-tag>
                </div>
                <div *ngIf="item.denimJobCreatedWhen" class="denim-job-created">Created {{ item.denimJobCreatedWhen }}</div>
              </div>
              <a *ngIf="getDenimJobLink(item)" [href]="getDenimJobLink(item)" style="font-size: 13px; white-space: nowrap;" target="_blank">
                View on Denim
              </a>
            </td>
            <!-- <td *ngIf="isTabAlreadyPaid">
              <div>{{displayDateTimeDB(item.paid?.when)}}</div>
              <a *ngIf="item.paid?.byUser?.id" class="link" target="_blank" 
                [routerLink]="[routeAdminUserList, item.paid.byUser.id]">
                {{getFullName(item.paid.byUser)}}
              </a>
            </td>
            <td *ngIf="isTabAlreadyPaid">
              <div style="white-space: pre;">{{item.paid?.note}}</div>
              <div *ngIf="item?.paid?.transferDate" class="top10">Transfer date: {{displayDateDB(item?.paid?.transferDate)}}</div>
            </td>
            <td *ngIf="shouldShowAction">

              <a class="link-item" (click)="openModalDownloadDoc(item)"><span nz-icon nzType="download" nzTheme="outline"></span> Doc</a>
              <ng-container *ngIf="isTabReadyToPay">
                <a class="link-item left15" (click)="markAsPaid(item)">
                  <i *ngIf="!item.loading" nz-icon nzType="check-circle" nzTheme="outline"></i>
                  <i *ngIf="item.loading" nz-icon nzType="loading" nzTheme="outline"></i>
                  Mark as already paid
                </a>
              </ng-container>

              <ng-container *ngIf="isTabAlreadyPaid">
                <a class="link-item left15" (click)="reopen(item)">
                  <i *ngIf="!item.loading" nz-icon nzType="reload" nzTheme="outline"></i>
                  <i *ngIf="item.loading" nz-icon nzType="loading" nzTheme="outline"></i>
                  Reopen
                </a>
                <a class="link-item left15" (click)="onBtnEditNote(item)"><span nz-icon nzType="edit" nzTheme="outline"></span> Edit note</a>
              </ng-container>

            </td> -->
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>