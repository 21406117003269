<div>
  <div class="icon-success-dialog"><i nz-icon nzType="check-circle" nzTheme="fill"></i></div>
  <div class="text-success-dialog"><span>Job Successfully Created!</span></div>
  <div class="top10">
    <div class="dlg-items">Job / PO # : {{ model?.reference_number || 'N/A' }}</div>
    <div class="dlg-items">Status : {{ model?.status || 'N/A' }}</div>
    <div class="dlg-items">
      <a [href]="model?.denimJobLink" target="_blank">View on Denim</a>
    </div>
    <div class="dlg-items top5">
      <button nz-button nzType="primary" style="width: 70px;" (click)="closeDialog()">Done</button>
    </div>
  </div>
</div>
