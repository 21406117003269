<div class="dashboard-child-container no-padding list">
  <div class="list-header flex no-padding" style="flex-direction: column; width: 100%;background-color: unset">
    <div style="margin-top: 20px;display: flex;width: 100%;align-items: center;justify-content: space-between">
      <div style="margin-left: 4px">Total: {{ totalCount }}</div>
    </div>
  </div>

  <div class="list-body">
    <nz-table #nzTable (nzPageIndexChange)="onDataListPageChanged($event)" [nzData]="listData"
      [nzFrontPagination]="false" [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
      [nzPageIndex]="pageIndex" [nzPageSize]="limit" [nzShowPagination]="totalCount > listData.length"
      [nzSize]="'default'" [nzTotal]="totalCount" nzBordered="true" nzSize="small">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>
          No results. Please fill the form and do search.
        </div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th nzColumnKey="age" nzWidth="40px">AGE</th>
          <th nzColumnKey="pickup" nzWidth="40px">PICKUP</th>
          <th nzColumnKey="origin" nzWidth="20px">ORIGIN
          </th>
          <th nzColumnKey="destination" nzWidth="30px">DESTINATION</th>
          <th nzColumnKey="equipment" nzWidth="60px">EQUIPMENT</th>
          <th nzColumnKey="status" nzWidth="50px">STATUS</th>
          <th nzColumnKey="owner" nzWidth="50px">OWNER</th>
          <th nzColumnKey="job" nzWidth="50px">ROUTING</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of listData; let i = index">
          <tr [ngClass]="{'deleted': item.delete}">
            <!--          AGE-->
            <td>
              <div>{{ getEventDuration(item.history?.serviced?.when) }}</div>
            </td>

            <!--          PICKUP-->
            <td>
              <div>{{ getDate(item.waypoints?.origin?.appointment?.startWhen) }}</div>
            </td>

            <!--          ORIGIN-->
            <td>
              <div>{{ item.waypoints?.origin?.city }}, {{ item.waypoints?.origin?.stateProv }}</div>
              <div class="general-info-label">{{ item.waypoints?.origin?.postalCode }}</div>
            </td>

            <!--          DESTINATION-->
            <td>
              <div>{{ item.waypoints?.destination?.city }}, {{ item.waypoints?.destination?.stateProv }}</div>
              <div class="general-info-label">{{ item.waypoints?.destination?.postalCode }}</div>
            </td>

            <!--          EQUIPMENT-->
            <td>
              <div>{{getEquipment(item.freight?.equipmentType)}}</div>
              <div class="general-info-label">{{getCapacity(item.freight)}}</div>
            </td>

            <!--          STATUS-->
            <td>
              <div>{{ item.status }}</div>
            </td>

            <!--          OWNER-->
            <td>
              <div>{{getOwner(item.history?.created)}}</div>
            </td>

            <!--          JOB-->
            <td>
              <div>
                <a [href]="goToDispatch(item.job?.id)" *ngIf="item.job?.id" target="_blank"
                  nz-tooltip nzTooltipTitle="Go to dispatch">
                  <i nz-icon nzType="rocket" nzTheme="outline"></i> {{ item.job?.code }}
                </a>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</div>
