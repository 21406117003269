import { Component, Input, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { environment } from "@env/environment";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { FormInputCost } from "../../../base/form-input-cost/comp";
import { DialogService } from "@dialogs/dialog.service";
import { ConfirmUnassignCarrier } from "@app/components/confirm-unassign-carrier";

@Component({
  selector: "[assign-carrier-with-cost]",
  templateUrl: "./index.html",
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../../styles/row-col.scss', './index.scss']
})
export class AssignCarrierWithCost extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    carrierId: { label: "Carrier", required: true },
    cost: { label: "", type: "formGroup", childItem: FormInputCost.declaration },
  };

  get isCreateNew(): boolean {
    return !this.model;
  }

  get btnSubmitLabel(): string {
    return this.isCreateNew ? 'Assign Carrier' : 'Update'
  }

  public isError = false;
  public isLoading = false;
  public listCarriers = [];
  public carrierUrl = '';

  public get totalQty(): number {
    return this.costForm?.qty ?? 0;
  }

  private _equipmentOrderText;
  @Input() set shipments(value) {
    this._equipmentOrderText = this.getEquipment(value);
  }

  @Input() jobId;
  @Input() carrierId; // nếu có carrierId tức là đã từng assign rồi -> gọi api để lấy dữ liệu
  @Input() matchedCarrier; //carrier info của carrer được chọn trong trường hợp gọi từ carrier bids sang.
  @ViewChild("costForm") costForm: FormInputCost;
  @Input() totalShipmentCost: number;
  @Input() baseCost: number = null;
  
  constructor() {
    super();
  }

  get noDataVehicle(): string {
    let carrierId = this.getItemValue("carrierId");
    if (!carrierId) return "Please select carrier first";
    return undefined; // return undefined to use default
  }

  get formGroupError() {
    let err = super.formGroupError;
    if (!err) {
      err = this.costForm?.formGroupError;
    }
    return err;
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
    this.carrierUrl = `${environment.carrierWebUrl}/dashboard/my-loads/${this.jobId}`;
  }

  private getDataDone(err = null) {
    if (err) {
      this.showErr(err);
      this.isError = true;
    } else {
      this.createFormInput(this.model);
      if (this.model) {
        // skip
      } else if (this.matchedCarrier?.carrierId) {
        //trường hợp không có model và có matchedCarrierId
        this.setItemValue("carrierId", this.matchedCarrier?.carrierId);
        this.setItemValue("cost.transitCost.rate", this.matchedCarrier.price);
        this.setItemValue("cost.transitCost.qty", 1);
      } else if (this.baseCost) {
        this.setItemValue("cost.transitCost.rate", this.baseCost);
        this.setItemValue("cost.transitCost.qty", 1);
      }
    }

    this.isLoading = false;
    if (this.isAdminReadOnlyRole) {
      this.setEnableFormGroup(false);
    }
  }

  private getData() {
    this.isLoading = true;
    let urls = [`${Const.APIURI_CARRIERS}?status=${Const.CarrierStatus.active}&limit=-1`];
    if (this.carrierId) {
      urls.push(`${Const.APIURI_JOBS}/${this.jobId}/metadata`);
    }
    this.api.concurrentGET(urls).subscribe(
      (resp) => {
        this.listCarriers = resp[0].data.list_data;
        if (resp.length > 1) {
          this.model = resp[1].data?.assignedCarrier;
        }
        this.getDataDone();
      },
      (err) => {
        this.getDataDone(err);
      }
    );
  }

  onBtnSave(): void {
    if (!this.needUpdate) {
      return;
    }
    let json = this.getFormData_JSON(true);
    this.assignCarrier(json);
  }

  onBtnUnassign() {
    DialogService.openFormDialog1(ConfirmUnassignCarrier, {
      nzComponentParams: {
        jobId: this.jobId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          console.log("resp", resp)
          this.setEnableFormGroup(false);
        }
      },
      nzClassName: 'modal-no-padding confirm-unassign-carrier-form',
    });
  }

  protected setEnableFormGroup(enabled: boolean): void {
    super.setEnableFormGroup(enabled);
    if (this.costForm) {
      this.costForm.isEnabled = enabled;
    }
  }

  protected onUpdateSuccess(resp) {
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  private assignCarrier(data) {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    this.setEnableFormGroup(false);
    this.startProgress();
    let isNegativeMargin = data.cost.grandTotal >= this.totalShipmentCost
    if(!isNegativeMargin){
      if(data.cost.negativeMarginReason) delete data.cost.negativeMarginReason
      if(data.cost.manager) delete data.cost.manager
    }
    this.api.PUT(`${Const.APIURI_JOBS}/${this.jobId}/assign_carrier`, data).subscribe(
      (resp) => {
        Log.d("assignCarrier done ", resp);
        this.onUpdateSuccess(resp);
        this.stopProgress();
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
        this.setEnableFormGroup(true);
      }
    );
  }

  public get equipmentOrderText() {
    return this._equipmentOrderText || 'N/A';
  }

  private getEquipment(shipments) {
    let arr = [];
    if (Utils.isArrayNotEmpty(shipments)) {
      for (let order of shipments) {
        if (order.shipmentType != Const.ShipmentTypes.fullTruckLoad) return null;
        if (order.shipmentModeId) {
          let shipmentMode = MasterData.getShipmentModeName(order.shipmentModeId) ?? '';
          if (order.equipmentId) {
            let equipment = MasterData.getEquipmenName(order.equipmentId);
            if (equipment) {
              if (shipmentMode) {
                shipmentMode += '/';
              }
              shipmentMode += equipment;
            }
          }
          if (!arr.includes(shipmentMode)) {
            arr.push(shipmentMode);
          }
        }
      }
    }
    return arr.join(', ');
  }

  copyCarrierUrl() {
    Utils.copyTextToClipboard(this.carrierUrl, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('URL has already been copied to the clipboard');
      }
    })
  }

  public getCarrierDOT(carrier): string {
    return carrier?.basicInfo?.dot ?? '';
  }

  public getCarrierNameWithMCandDOT(carrier): string {
    if(carrier?.basicInfo?.mc && carrier?.basicInfo?.dot)
    return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc}/DOT:${carrier?.basicInfo?.dot})`
    if (carrier?.basicInfo?.mc) return `${carrier?.basicInfo?.name} (MC:${carrier?.basicInfo?.mc})`
    if (carrier?.basicInfo?.dot) return `${carrier?.basicInfo?.name} (DOT:${carrier?.basicInfo?.dot})`
    return carrier?.basicInfo?.name ?? '';
  }
}
