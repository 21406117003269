import { Component } from "@angular/core";
import { BaseDialog } from "@dialogs/base-dlg";

@Component({
  selector: '[create-statement-mode-dlg]',
  templateUrl: './index.html',
  styleUrls: ['index.scss']
})
export class CreateStatementModeDlg extends BaseDialog {

  public onOk: (data) => void = (data) => {};

  public createShipmentStatementTypes = [
    { id: 'normal', name: 'Normal'},
    { id: 'carrierFixedRate', name: 'For Carrier Fixed Rate'},
  ];

  onBtnSelectEditMode(id) {
    this.onOk(id);
    this.closeDialog();
  }

}