import { EventEmitter, Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class FinService {
  public onRefreshARBatchDetail: EventEmitter<any> = new EventEmitter<any>();
  public onRefreshAPBatchDetail: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  refreshDataARBatchDetail() {
    this.onRefreshARBatchDetail.emit();
  }

  refreshDataAPBatchDetail() {
    this.onRefreshAPBatchDetail.emit();
  }

}
