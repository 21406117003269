export enum FinArTabFilter {
  notFinished = 'notFinished',
  needPodUpload = 'needPodUpload',
  needPodConfirm = 'needPodConfirm',
  readyToProcess = 'readyToProcess',
  alreadyProcessed = 'alreadyProcessed',
}

export interface FinArCountTab {
  notFinished?: number,
  needPodUpload?: number,
  needPodConfirm?: number,
  readyToProcess?: number,
  alreadyProcessed?: number,
}
