<div class="dashboard-child-container user-detail no-padding">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <div class="group">
      <div [ngClass]="{'flex': !isMobile}">
        <div nz-row style="max-width: 1024px;">
          <div *ngFor="let key of keys; let i = index" nz-col [nzSpan]="11" [nzOffset]="i % 2 == 0 ? 0 : 1">
            <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
            <nz-form-item class="mw500">
              <nz-form-control>
                <ng-container [ngSwitch]="key">
                  <select-by-searching *ngSwitchCase="'carrierId'" 
                    [version]="2"
                    [formControlName]="key" type="carrier"
                    [apiUrl]="getApiListDataForFilter(key)"
                    [(listData)]="listCarriers"
                    [placeholder]="getPlaceHolder(key)">
                  </select-by-searching>
                  <input *ngSwitchDefault nz-input [formControlName]="key" 
                    [type]="getInputType(key)"
                    maxLength="20"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)">
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div [ngClass]="{'notify-via-sms': !isMobile}">
          <div nz-checkbox [formControlName]="'isNotifyViaSMS'" [ngClass]="{'notify-via-sms-checkbox': !isMobile}">{{getLabel('isNotifyViaSMS')}}</div>
        </div>
      </div>
      <ng-container *ngIf="linkedUserDesc">
        <div class="form-label-v2">Linked to user: <a target="_blank" [routerLink]="[routeAdminUserList, model.linkedUser.id]">{{linkedUserDesc}}</a></div>
      </ng-container>
    </div>

    <!-- Button save & cancel -->
    <div class = "footer-btn">
      <button *ngIf="shouldShowHistory" nz-button (click)="goToHistory(model, routeAdminDriverList)">
        <i nz-icon nzType="history" nzTheme="outline"></i>
        View Changes History
      </button>
      <div *ngIf="requirePermissions([PermissionCode.driver.create, PermissionCode.driver.update])" detail-footer-buttons labelButtonCreate="Create New Driver"
        [isCreateNew]="isCreateNew" [isEditing]="isEditing"
        [onProgress]="onProgress" [needUpdate]="needUpdate"
        (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
      </div>
    </div>
  </form>
</div>