import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseQuickbooksComponent } from "../base";
import { Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subscription } from "rxjs";
import { InputHelper } from "@services/input-helper";

@Component({
  selector: 'create-quickbooks-vendor',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class CreateQuickbooksVendor extends BaseQuickbooksComponent {

  @Input() accountInfo;
  isFetchingTerms = false;
  qbPaymentTermOptions: { id: string, name: string, DueDays: number }[] = [];
  private apiReadySubscription: Subscription;

  protected formGroupDeclaration: FormGroupDeclaration = {
    companyName: { label: "Company name", required: true },
    email: { label: "Email", notAcceptEmpty: true, validators: Validators.email },
    phone: { label: "Phone", notAcceptEmpty: true, inputType: 'tel', getValue: InputHelper.getValuePhone, formatValue: InputHelper.formatPhone },
    firstName: { label: "First Name", notAcceptEmpty: true },
    lastName: { label: "Last Name", notAcceptEmpty: true },
    website: { label: "Website", notAcceptEmpty: true },
    paymentTerms: { label: "Payment terms", required: true },
    address: { label: "Address", notAcceptEmpty: true },
    otherInfomation: { label: "Other information", notAcceptEmpty: true },
  };

  constructor(private modal: NzModalRef) {
    super();
  }

  ngOnInit(): void {
    const arr = [];
    if (this.accountInfo?.basicInfo?.mc) arr.push(`MC: ${this.accountInfo?.basicInfo?.mc}`);
    if (this.accountInfo?.basicInfo?.dot) arr.push(`DOT: ${this.accountInfo?.basicInfo?.dot}`);
    this.model = {
      companyName: this.accountInfo?.basicInfo?.name,
      email: this.accountInfo?.basicInfo?.email || this.accountInfo?.basicInfo?.owner?.email,
      phone: this.accountInfo?.basicInfo?.phone || this.accountInfo?.basicInfo?.owner?.phone,
      firstName: this.accountInfo?.basicInfo?.owner?.firstName,
      lastName: this.accountInfo?.basicInfo?.owner?.lastName,
      website: this.accountInfo?.basicInfo?.website,
      paymentTerms: '',
      address: this.accountInfo?.basicInfo?.address,
      otherInfomation: arr.join(', '),
    }
    super.ngOnInit();
    this.apiReadySubscription = this.apiReady$.subscribe(async (res) => {
      if (res) {
        await this.fetchingDataQBTerms();
      }
    });
  }

  ngOnDestroy() {
    if (this.apiReadySubscription) {
      this.apiReadySubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public row1: FormRow = {
    columns: [
      {key: 'email', span: 12 },
      {key: 'phone', span: 12 },
    ]
  };

  public row2: FormRow = {
    columns: [
      {key: 'firstName', span: 12 },
      {key: 'lastName', span: 12 },
    ]
  };

  onBtnClose() {
    this.closeDialog();
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  private async fetchingDataQBTerms() {
    this.isFetchingTerms = true;
    const res = await this.quickbookService.getAllPaymentTerms().catch(err => {
      this.handleErrorQueryQuickbooks(err);
      this.isFetchingTerms = false;
    });
    if (res?.length) {
      this.qbPaymentTermOptions = res.map(item => ({
        id: item.Id,
        name: item.Name,
        DueDays: item.DueDays,
      }));
    }
    this.isFetchingTerms = false;
  }

  onBtnCreateVendor() {
    if (!this.needUpdate || this.onProgress) return;
    const data = this.getFormData_JSON(true);
    if (!data) return;
    data.finAccountId = this.accountInfo?.finAccount?.id;
    const url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/create-vendor`);
    this.startProgress();
    return this.api.POST(url, data).subscribe(
      (resp) => {
        this.showSuccess(resp);
        this.closeDialog();
        this.stopProgress();
      },
      (err) => {
        this.handleErrorQueryQuickbooks(err);
        this.stopProgress();
      }
    );
  }
  
}
