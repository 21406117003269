import { Component } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';
import { ResponseAdminFinBatchListItem } from '@wearewarp/types/rest-api/admin/fin';
import { FinApCountTab, FinApTabFilter } from '../interface';
import { Utils } from '@services/utils';
import { FinUtil } from '../../util';
import { ImageUtil } from '@services/image-util';
import { DialogService } from '@dialogs/dialog.service';
import { AttachedFile } from '@wearewarp/types/data-model';
import { CreateStatementModeDlg } from '../select-create-statement-dlg';
import { CreateFinStatementForFixedRate } from '../../components/fin-statement/create-for-carrier-fixed-rate';
import { DetailFinStatement } from '../../components/fin-statement/detail';
import { StatementCreateSuccessfully } from '../../components/fin-statement/create-success-dialog';

@Component({
  selector: 'fin-payable-batch-list',
  templateUrl: './index.html',
  styleUrls: ['../../../list.scss', './index.scss', '../../status.scss']
})
export class FinPayableBatchList extends BaseList<ResponseAdminFinBatchListItem> {
  private _selectedTabIndex = 0;
  public countSum: FinApCountTab = {}
  public isUploading: any = {}
  constructor() {
    super();
  }

  get currentTabFilter(): FinApTabFilter {
    switch (this._selectedTabIndex) {
      case 0: return FinApTabFilter.needPodUpload;
      case 1: return FinApTabFilter.needPodConfirm;
      case 2: return FinApTabFilter.readyToProcess;
      case 3: return FinApTabFilter.alreadyProcessed;
      case 4: return FinApTabFilter.notFinished;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
  }

  showBatchCode(item) {
    return `AP-${item.code}`;
  }

  getRouterLinkDetail(item) {
    return `${Const.routeAdminFinAP}/${item.id}`;
  }

  public getAmountValue(item) {
    return '$' + InputHelper._formatMoney(item.amt, 0);
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  protected getApiUrl(): string {
    return this.getApiByTab(this.currentTabFilter);
  }

  private updateCountSum(tab: FinApTabFilter, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private countAll() {
    [
      FinApTabFilter.notFinished,
      FinApTabFilter.needPodUpload,
      FinApTabFilter.needPodConfirm,
      FinApTabFilter.readyToProcess,
      FinApTabFilter.alreadyProcessed,
    ].map(it => this.countForTab(it));
  }

  private countForTab(tab: FinApTabFilter) {
    const params = this.prepareParamGetList();
    const url = Utils.appendQueryStringIntoUrl(`${this.getApiByTab(tab)}?countOnly=true`, params);
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.total != null) {
          this.updateCountSum(tab, resp.data.total);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  private getApiByTab(tab: FinApTabFilter) {
    switch (tab) {
      case FinApTabFilter.notFinished: return Const.APIURI_FINANCES_BATCH('list_not_finished');
      case FinApTabFilter.needPodUpload: return Const.APIURI_FINANCES_BATCH('list_need_pod_upload');
      case FinApTabFilter.needPodConfirm: return Const.APIURI_FINANCES_BATCH('list_need_pod_confirm');
      case FinApTabFilter.readyToProcess: return Const.APIURI_FINANCES_BATCH('list_ready_to_process');
      case FinApTabFilter.alreadyProcessed: return Const.APIURI_FINANCES_BATCH('list_already_processed');
    }
  }

  batchStatus(item: ResponseAdminFinBatchListItem) {
    return FinUtil.showStatusFinIntermediate(item.intermediateStatus);
  }

  getCarrierInvoice(item: ResponseAdminFinBatchListItem){
    return item?.invoiceFiles;
  }

  getCarrierInvoiceName(item: ResponseAdminFinBatchListItem, index: any){
    return `INV-${item?.relatedBiz?.code}-${index+1}`;
  }

  onClickAddFile(item: ResponseAdminFinBatchListItem){
    if(item?.finJobs?.length > 1){
      return this.showErr("Please upload invoice for fin job separately.");
    }
    let element: HTMLElement;
    element = document.getElementById(`input-${item.id}`) as HTMLElement;
    element.click()
  }

  onFileSelected(inputElement: HTMLInputElement, item: ResponseAdminFinBatchListItem) {
    let file = inputElement.files[0];
    inputElement.value = "";
    ImageUtil.resizeImageIfNeeded(file).then((blob) =>{
      this.uploadCarrierInvoice(blob, blob.name, item);
    });
  }

  private uploadCarrierInvoice(file: Blob, fileName: string, item: ResponseAdminFinBatchListItem){
    this.isUploading[item.id] = true;
    let apiUrl = `${Const.APIV2(Const.APIURI_JOBS)}/upload-carrier-invoice`;
    let formData = new FormData();
    const jsonData: any = {
      'jobId': item.relatedBiz.id,
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadInvoice", file, fileName);
    this.api.postFormData(apiUrl, formData).subscribe(
      (resp) => {
        this.isUploading[item.id] = false;
        this.getData()
        this.showSuccess("Your invoice has been added successfully.");
      },
      (err) => {
        this.isUploading[item.id] = false;
        this.showErr(err);
      }
    )
  }

  onBtnCreateStatement(event) {
    DialogService.openDialog(CreateStatementModeDlg, {
      replaceWrapClassName: true,
      nzClassName: '',
      nzCentered: true,
      nzComponentParams: {
        onOk: type => this.doCreateStatement(type)
      }
    });
  }

  private doCreateStatement(type) {
    if (type === 'carrierFixedRate') {
      DialogService.openFormDialog1(CreateFinStatementForFixedRate, {
        nzComponentParams: {
          closeOnSuccess: false,
          onDone: (resp) => {
            this.onBtnRefresh();
            DialogService.openDialog(StatementCreateSuccessfully, {
              nzWidth: 400,
              nzClosable: false,
              nzMaskClosable: false,
              nzComponentParams: {
                model: resp?.data ?? {}
              },
            });
          }
        },
        nzClassName: "modal-xl",
      });
    } else {
      this.comingSoon();
    }
  }

  onBtnViewStatement(item: ResponseAdminFinBatchListItem) {
    if (!item.statement?.id) return;
    DialogService.openDialog1(DetailFinStatement, {
      nzComponentParams: {
        id: item.statement.id,
      },
      nzClassName: "modal-xl",
    });
  }

  getLabelViewStatement(item: ResponseAdminFinBatchListItem) {
    if (item.statement?.id) {
      return `Statement ST-${item.statement.code}`;
    }
    return 'View Statement'
  }
}