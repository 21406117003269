<div class="bottom20" style="min-height: 200px;">
  <div *ngIf="isLoadingService" style="padding-top: 20px;">
    <nz-spin nzTip="Loading..."></nz-spin> 
  </div>
  <div *ngIf="!isLoadingService">
    <div *ngIf="errorAuthMsg">
      <div class="error bottom10"> {{ errorAuthMsg }}</div>
      <button nz-button class="btn-connect-to-quickbooks" (click)="onBtnConnectToQuickbooks()">Connect to Quickbooks</button>
    </div>
    <div *ngIf="!errorAuthMsg">
      <!-- begin view main-->
      <div *ngIf="accountInfo" class="bottom20">
        <div class="medium bottom5">WARP Carrier Information</div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">Name: </div>
          <div>{{ accountInfo.basicInfo?.name || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">MC: </div>
          <div>{{ accountInfo.basicInfo?.mc || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">DOT: </div>
          <div>{{ accountInfo.basicInfo?.dot || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">Address: </div>
          <div>{{ getAddressText(accountInfo.basicInfo?.address) || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">Website: </div>
          <div>{{ accountInfo.basicInfo?.website || 'N/A' }}</div>
        </div>
      </div>
      <form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput" nzLayout="vertical">
        <div class="f15 medium bottom10">Quickbooks Form</div>
        <div *ngFor="let key of ['companyName', 'website']">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input 
                [type]="getInputType(key)"
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)"
                (keypress)="onInputKeyPress($event, key)"
                [formControlName]="key"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col *ngFor="let col of row1.columns" [nzSpan]="col.span">
            <div *ngIf="col.key as key">
              <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input 
                    [type]="getInputType(key)"
                    [placeholder]="getPlaceHolder(key)"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)"
                    [formControlName]="key"/>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div nz-row [nzGutter]="16">
          <div nz-col *ngFor="let col of row2.columns" [nzSpan]="col.span">
            <div *ngIf="col.key as key">
              <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
              <nz-form-item>
                <nz-form-control>
                  <input nz-input 
                    [type]="getInputType(key)"
                    [placeholder]="getPlaceHolder(key)"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)"
                    [formControlName]="key"/>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
        <div *ngFor="let key of ['address']">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <form-address [formControlName]="key"></form-address>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div *ngIf="'paymentTerms' as key">
          <nz-form-item>
            <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
            <nz-form-control>
              <nz-select nzBackdrop="true" [formControlName]="key" [nzLoading]="isFetchingTerms"
                nzPlaceHolder="Please select term" nzDropdownClassName="choose-carrier-payment-term">
                <nz-option nzCustomContent *ngFor="let option of qbPaymentTermOptions" [nzLabel]="option.name" [nzValue]="option.id">
                  {{ option.name }}
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div *ngIf="'otherInfomation' as key">
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <nz-form-item>
            <nz-form-control>
              <input nz-input 
                [type]="getInputType(key)"
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)"
                (keypress)="onInputKeyPress($event, key)"
                [formControlName]="key"/>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="bottom20 flex" style="flex-direction: row-reverse; margin-top: 30px;" >
          <button 
            nz-button 
            nzType="primary" 
            [nzLoading]="onProgress"
            [disabled]="!needUpdate || onProgress"
            (click)="onBtnCreateVendor()"
          >Create Vendor</button>
        </div>
      </form>
      <!-- end view main-->
    </div>
  </div>
</div>





