<div *ngIf="!data" class="nodata">No data</div>

<ng-container *ngIf="data">
  <div style="display: flex; margin-bottom: 20px;">
    <div class="title">{{displayData.txDesc}}</div>
    <div class="when-by">
      <i nz-icon nzType="clock-circle" nzTheme="outline" class="right5"></i>
      {{displayDateTimeDB(data.created.when)}}
      by
      <a class="link left5" target="_blank" [routerLink]="[routeAdminUserList, data.created.byUser.id]">{{getFullName(data.created.byUser)}}</a>
    </div>
     
  </div>
  <ng-container *ngIf="displayData.transitCost.length > 0" [ngTemplateOutlet]="transitCost" [ngTemplateOutletContext]="{data: displayData.transitCost}"></ng-container>
  <ng-container *ngIf="displayData.fuelCost.length > 0" [ngTemplateOutlet]="fuelCost" [ngTemplateOutletContext]="{data: displayData.fuelCost}"></ng-container>
  <ng-container *ngIf="displayData.volumeDiscount.length > 0" [ngTemplateOutlet]="volumeDiscount" [ngTemplateOutletContext]="{data: displayData.volumeDiscount}"></ng-container>
  <ng-container *ngIf="displayData.serviceOptions.length > 0" [ngTemplateOutlet]="serviceOptions" [ngTemplateOutletContext]="{data: displayData.serviceOptions}"></ng-container>
</ng-container>

<ng-template #transitCost let-data="data">
  <nz-table #nzTable [nzData]="data" nzBordered [nzShowPagination]="false">
    <thead>
      <tr>
        <th>Rate</th>
        <th>Quantity</th>
        <th>Subtotal</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of nzTable.data; let i = index" [ngClass]="{'strike-through': i == 0 && data.length > 1}">
        <td>{{item.rate}}</td>
        <td>{{item.qty}}</td>
        <td>{{item.total}}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>

<ng-template #fuelCost let-data="data">
  <nz-table #nzTable [nzData]="data" nzBordered [nzShowPagination]="false">
    <thead>
      <tr>
        <th>Type</th>
        <th>Rate</th>
        <th>Quantity</th>
        <th>Subtotal</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of nzTable.data; let i = index" [ngClass]="{'strike-through': i == 0 && data.length > 1}">
        <td>{{item.type}}</td>
        <td>{{item.rate}}</td>
        <td>{{item.qty}}</td>
        <td>{{item.total}}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>

<ng-template #volumeDiscount let-data="data">
  <nz-table #nzTable [nzData]="data" nzBordered [nzShowPagination]="false">
    <thead>
      <tr>
        <th>Type</th>
        <th>Rate</th>
        <th>Subtotal</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of nzTable.data; let i = index" [ngClass]="{'strike-through': i == 0 && data.length > 1}">
        <td>{{item.type}}</td>
        <td>{{item.rate}}</td>
        <td>{{item.total}}</td>
      </tr>
    </tbody>
  </nz-table>
</ng-template>

<ng-template #serviceOptions let-data="data">
  <nz-table #nzTable [nzData]="data" nzBordered [nzShowPagination]="false">
    <thead>
      <tr>
        <th>Option</th>
        <th>Rate</th>
        <th>Quantity</th>
        <th>Subtotal</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of nzTable.data; let i = index">
        <ng-container *ngIf="item.length == 0">
          <tr>
            <td colspan="4"><div class="no-option strike-through">[No data]</div></td>
          </tr>
        </ng-container>
        <ng-container *ngIf="item.length > 0">
          <tr *ngFor="let op of item;" class="row-{{i}}" [ngClass]="{'strike-through': i == 0 && data.length > 1}">
            <td>{{op.name}}</td>
            <td>{{op.rate}}</td>
            <td>{{op.qty}}</td>
            <td>{{op.total}}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </nz-table>
</ng-template>