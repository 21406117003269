import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";
import { Stop, TimeWindow } from "@wearewarp/types/data-model";
import TaskEntity from "./TaskEntity";
import { getInjector } from "@services/injector";
import { DispatchService } from "../dispatchService";
import { Const } from "@wearewarp/universal-libs";
import { DateUtil } from "@services/date-utils";

export default class StopEntity {
  private data: Stop;
  private index: number;
  private tasks: TaskEntity[];
  private dispatchService: DispatchService;
  private estimateTraffic: { mileage: string, hours: string};

  constructor() {
    const injector = getInjector();
    this.dispatchService = injector.get(DispatchService);
  }
  async init(data) {
    this.data = data;
    // this.tasks = this.data.taskIds.map(taskId => this.dispatchService.getTaskById(taskId));
    return this;
  }

  setDispatchService(dispatchService) {
    if (dispatchService) {
      this.dispatchService = dispatchService;
    }
    return this;
  }

  setIndex(index) {
    this.index = index;
    return this;
  }

  setEstimateTraffic(data) {
    let getMileage = (data) => {
      if (!data) return 'N/A'
      const totalDistance = data.distance;
      if (!totalDistance) return 'N/A'
      return (totalDistance / 1609.34).toFixed(2).toLocaleString()
    }
    let getHours = (data) => {
      if (!data) return 'N/A'
      const totalTime = data.time;
      if (!totalTime) return 'N/A'
      return (totalTime / 3600.00).toFixed(2).toLocaleString()
    }
    this.estimateTraffic = {
      mileage: getMileage(data),
      hours: getHours(data),
    }
  }

  getEstimateTraffic() {
    return this.estimateTraffic;
  }

  getIndex() {
    return this.index
  }
  getType() {
    return this.data.type
  }

  getId() {
    return this.data.id
  }

  getStatus() {
    return this.data.status
  }

  getLocationName() {
    return this.data.info.locationName || "No Name"
  }
  getAddressText() {
    return MasterData.getAddressText(this.data.info.addr)
  }
  getAddress() {
    return this.data.info.addr
  }
  getDeliveryInfo() {
    return this.data.info
  }

  getAppointment() {
    const appointment = this.data.info.appointmentInfo;
    if (appointment?.from) return appointment

    const timeWindow = this.data.info?.windows?.[0]
    if (timeWindow?.from) return timeWindow;
  }
  getTimeWindows() {
    return this.data.info?.windows;
  }
  getReasonCodeId() {
    return this.data.info['reasonCodeId'] || '';
  }
  isAppointmentSchduled() {
    return this.data.info?.appointmentInfo?.from ? true : false;
  }

  getAppointmentIssue() {
    if (this.data.info?.requiresAppointment && !this.isAppointmentSchduled()) return "Requires Appointment";
    if (this.data?.metadata?.conflictTime) return "Conflict time between shipments"
  }

  isMixedStatus() {
    return this.data?.metadata?.mixedStatus
  }
  getTimezone() {
    return this.data?.info?.addr?.metadata?.timeZoneStandard
  }
  getFirstTask(): TaskEntity | undefined {
    return this.getTasks()?.find(task => ![Const.TaskStatus.canceled, Const.TaskStatus.pickupFailed].includes(<any>task.getStatus()));

  }
  getArrivedTime() {
    return this.getFirstTask()?.getArrivedTime()
  }
  getDepartedTime() {
    return this.getFirstTask()?.getDepartedTime()
  }
  getETA() {
    return this.getFirstTask()?.getETA()
  }

  getTotalPod() {
    return this.getFirstTask()?.getTotalPod() ?? 0;
  }

  getPodNotConfirmed() {
    return this.getFirstTask()?.getPodNotConfirmed() ?? 0;
  }

  getRefNums() {
    let refNums = [];
    for (let task of this.getTasks()) {
      refNums = [ ...refNums, ...task.getRefNums() ];
    }
    return Utils.uniqElementsArray(refNums);
  }

  getTasks() {
    return this.data.taskIds.map(taskId => this.dispatchService.getTaskById(taskId))
  }

  getShipments() {
    return this.getTasks().map(task => task.getShipment()).filter(x => x);
  }

  getShipmentDeliveryInfo() {
    if (this.getType() == Const.TaskType.PICKUP) {
      return this.getShipments().map(item => item.getPickInfo());
    } else if (this.getType() == Const.TaskType.DROPOFF) {
      return this.getShipments().map(item => item.getDropInfo());
    }
    else if (this.getType() == Const.TaskType.RETURN) {
      return this.getShipments().map(item => item.getReturnInfo());
    }
    return []
  }

  isExistsReturnTask() {
    let arr = this.getTasks().filter(task => task?.isExistsReturn());
    return arr.length ? true : false;
  }

  getWarningStatus() {
    if (this.getTasks().length <= 1) return null;
    let statusTasks = this.getTasks().map(task => task.getStatus());
    let isSame = true;
    for (let i=0; i<statusTasks.length; i++) {
      if (statusTasks[i] !== statusTasks[0]) {
        isSame = false;
        break;
      }
    }
    if (isSame) {
      return null;
    } else {
      return this.getTasks().map(task => `Shipment ${task.getShipmentCodeText()}: ${Utils.capitalizeFirstLetter(task.getStatus())}`);
    }
  }

  getWarningAppointment() {
    if (this.getTasks().length <= 1) return null;
    let getDisplayTime = (window, timezone) => {
      return DateUtil.displayTimeWindow(window, {
        timezone: timezone,
        formatDateOnly: 'MM/DD/YY',
        format: "MM/DD/YY h:mm:ss A",
      })
    }
    const appointment = this.data.info.appointmentInfo;
    if (appointment?.from) {
      let appointments = this.getTasks().map(task => task?.toJSON().info?.appointmentInfo);
      let isSame = this.checkSameScheduleTimes(appointments);
      if (isSame) return null;
      
      return this.getTasks().map(task =>
        (`Shipment ${task.getShipmentCodeText()}:
        ${getDisplayTime(task?.toJSON().info?.appointmentInfo, this.getTimezone())}`)
      );
    }

    const timeWindow = this.data.info?.windows?.[0]
    if (timeWindow?.from) {
      let timeWindows = this.getTasks().map(task => task?.toJSON().info?.windows?.[0]);
      let isSame = this.checkSameScheduleTimes(timeWindows);
      if (isSame) return null;
        
      return this.getTasks().map(task =>
        (`Shipment ${task.getShipmentCodeText()}:
        ${getDisplayTime(task?.toJSON().info?.windows?.[0], this.getTimezone())}`)
      );
    }
  }

  private checkSameScheduleTimes(windows: TimeWindow[]) {
    let isSame = true;
    for (let i = 1; i < windows.length; i++) {
      if (this.compareDateYYYYMMDDHHmm(windows[i]?.from, windows[0]?.from) || this.compareDateYYYYMMDDHHmm(windows[i]?.to, windows[0]?.to)) {
        isSame = false;
        break;
      }
    }
    return isSame
  }

  private compareDateYYYYMMDDHHmm(date1, date2) {
    return DateUtil.diff(date1, date2, { skipSecond: true })
  }

  getWarningPod() {
    let tasksNumber = this.getTasks().length;
    if (tasksNumber <= 1) return null;
    let podTasks =this.getTasks().map(task => task.getPodUploadFiles());
    let isSameLength = true;
    for (let i=1; i<podTasks.length; i++) {
      if (podTasks[i].length != podTasks[0].length) {
        isSameLength = false;
        break;
      }
    }
    if (!isSameLength) {
      return `Pods of ${tasksNumber} tasks are diffirent.`;
    } else {
      let arr = podTasks.map(pods => pods.map(pod => String(pod).trim()).sort());
      let isSameContent = true;
      for (let i=1;i<arr.length; i++) {
        if (!Utils.isArraysTheSame(arr[i], arr[0])) {
          isSameContent = false;
          break;
        }
      }
      if (isSameContent) {
        return null;
      } else {
        return `Pods of ${tasksNumber} tasks are diffirent.`;
      }
    }
  }

  getShipmentClientIds() {
    let clientIds = this.getShipments().map(item => item.getClientId());
    return Utils.uniqElementsArray(clientIds);
  }

  toJSON(){
    return this.data
  }

  getScanningBarcodeResults() {
    return this.data['items'];
  }
}
