<div>
  <div class="icon-success-dialog"><i nz-icon nzType="check-circle" nzTheme="fill"></i></div>
  <div class="text-success-dialog"><span>Bill Successfully Created!</span></div>
  <div class="top10">
    <div class="dlg-items">Bill no : {{ model?.DocNumber || 'N/A' }}</div>
    <div class="dlg-items">
      <a [href]="model?.qbBillLink" target="_blank">View on Quickbooks</a>
    </div>
    <div class="dlg-items top5">
      <button nz-button nzType="primary" style="width: 70px;" (click)="closeDialog()">Done</button>
    </div>
  </div>
</div>
