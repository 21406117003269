<div>
  <div class="icon-success-dialog"><i nz-icon nzType="check-circle" nzTheme="fill"></i></div>
  <div class="text-success-dialog"><span>Statement Successfully Created!</span></div>
  <div class="top10">
    <div class="dlg-items">Code : {{ statementCode }}</div>
    <div class="dlg-items" *ngIf="isPaymentServiceDenim">
      <button nz-button nzType="primary" style="width: 170px;" (click)="onBtnCreateDenimJob()">Create Denim Job</button>
    </div>
    <div class="dlg-items top5">
      <button nz-button nzType="default" style="width: 70px;" (click)="closeDialog()">Done</button>
    </div>
  </div>
</div>
