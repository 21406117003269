import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { BaseQuickbooksComponent } from "../base";
import { ResponseQuickbooksVendor } from "@wearewarp/types/data-model/types/finance";
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'search-quickbooks-vendor',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class SearchQuickbooksVendor extends BaseQuickbooksComponent {

  @Input() accountInfo;
  @Input() selectedVendor: ResponseQuickbooksVendor;
  @Input() onCreateVendor: () => void;
  public isLoadingVendor = false;
  public listOfData: ResponseQuickbooksVendor[] = [];
  public limit = 3;

  protected formGroupDeclaration: FormGroupDeclaration = {
    companyName: { label: "Vendor name", required: true }
  };

  constructor(private modal: NzModalRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.selectedVendor) {
      const _data = [ this.selectedVendor ];
      this.buildDisplayInfo(_data);
      this.listOfData = _data;
    }
  }

  private closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  onBtnSearchCompany() {
    const companyName = this.getItemValue('companyName');
    if (!companyName) {
      this.showWarning('', `Please enter vendor name`);
      return;
    }
    const params = {
      query: companyName,
    }
    this.isLoadingVendor = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/search-vendor`);
    url = Utils.appendQueryStringIntoUrl(url, params);
    this.api.GET(url).subscribe(
      resp => {
        let _data;
        if (this.selectedVendor?.Id) {
          _data = (resp?.data?.list_data ?? []).filter(item => !this.isSelected(item));
          _data = [ this.selectedVendor, ..._data];
        } else {
          _data = resp?.data?.list_data ?? [];
        }
        this.buildDisplayInfo(_data);
        this.listOfData = _data;
        this.isLoadingVendor = false;
      }, err => {
        this.handleErrorQueryQuickbooks(err)
        if (this.selectedVendor) {
          const _data = [ this.selectedVendor ];
          this.buildDisplayInfo(_data);
          this.listOfData = _data;
        } else {
          this.listOfData = [];
        }
        this.isLoadingVendor = false;
      }
    );
  }

  private buildDisplayInfo(items: ResponseQuickbooksVendor[]) {
    for (let item of items) {
      item['displayInfo'] = {
        companyName: item.CompanyName,
        qbLink: this.isProduction ? `https://qbo.intuit.com/app/vendordetail?nameId=${item.Id}` : `https://sandbox.qbo.intuit.com/app/vendordetail?nameId=${item.Id}`,
        billAddr: item.BillAddr?.City ? this.getAddressText({
          city: item.BillAddr.City,
          street: item.BillAddr.Line1,
          street2: item.BillAddr.Line2,
          zipcode: item.BillAddr.PostalCode,
          state: item.BillAddr.CountrySubDivisionCode,
        }) : 'N/A',
        email: item.PrimaryEmailAddr?.Address || 'N/A',
        phone: item.PrimaryPhone?.FreeFormNumber || 'N/A',
        website: item.WebAddr?.URI || 'N/A',
        otherInfomation: item.AlternatePhone?.FreeFormNumber || 'N/A',
      }
    }
  }

  isSelected(item: ResponseQuickbooksVendor) {
    return this.selectedVendor?.Id === item.Id;
  }

  onBtnConfirm(item: ResponseQuickbooksVendor) {
    this.isLoadingVendor = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${this.accountInfo?.finAccount?.id}/quickbooks`);
    url = Utils.appendQueryStringIntoUrl(url, {type: 'add'});
    this.api.PUT(url, item).subscribe(
      resp => {
        this.selectedVendor = item;
        this.showSuccess('Success', 'Relationship confirm successfully');
        this.isLoadingVendor = false;
      }, err => {
        this.showErr(err)
        this.isLoadingVendor = false;
      }
    );
  }

  onBtnRemove(item: ResponseQuickbooksVendor) {
    this.isLoadingVendor = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${this.accountInfo?.finAccount?.id}/quickbooks`);
    url = Utils.appendQueryStringIntoUrl(url, {type: 'remove'});
    this.api.PUT(url, item).subscribe(
      resp => {
        this.selectedVendor = null;
        this.showSuccess('Success', 'Relationship removed successfully');
        this.isLoadingVendor = false;
      }, err => {
        this.showErr(err)
        this.isLoadingVendor = false;
      }
    );
  }

  onBtnCreateVendor() {
    this.closeDialog();
    this.onCreateVendor();
  }

  
}
