import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { SharedModule } from "../shared/shared.module";
import { DialogsModule } from "@dialogs/dialogs.module";
import { DetailModule } from "@base/detail.module";
import { FormAddressUSModule } from "@base/form-address-us/module";
import { DatTruckList } from "./truck/list";
import { ServiceAreaModule } from "@components/service-area/module";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { DatTruckFilter } from "@app/admin/dat/truck/truck-filter";
import { UiCommonModule } from "@components/common/module";
import { DatTruckDetailComponent } from "@app/admin/dat/truck/truck-detail/truck-detail";
import { DatPostList } from "./post/list";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzStepsModule,
    NzTableModule,
    NzTagModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzAutocompleteModule,
    SearchBoxModule,
    SharedModule,
    DialogsModule,
    DetailModule,
    FormAddressUSModule,
    ServiceAreaModule,
    NzEmptyModule,
    UiCommonModule,
  ],
  declarations: [DatTruckList, DatTruckFilter, DatTruckDetailComponent, DatPostList],
  exports: [DatTruckList, DatPostList],
  providers: [],
})
export class DatModule {}
