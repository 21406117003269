<form [formGroup]="formInput" nz-form style="margin: 0px; padding: 0px;">
  <div style="width: 100%; background-color: white;">
    <div style="display: flex; flex-wrap: wrap;padding: 20px;margin-top: 20px">
      <!--     Origin filter-->
      <div class="form-item flex1">
        <div class="single-line">Origin City or State
          <span *ngIf="isRequired('origin')" class="label-mark-required"></span>
        </div>
        <input #powerLaneInputFrom (input)="onInputChanged($event, 'origin')"
                [nzAutocomplete]="autoComplete1"
                [placeholder]="'Enter to search'"
                [formControlName]="'origin'"
                id="input-origin"
                nz-input>
        <nz-autocomplete #autoComplete1 [compareWith]="compareAddress">
          <nz-auto-option *ngFor="let item of listAddressAutoComplete['input-origin']"
                          [nzValue]="item" [nzLabel]="getLocationName(item)">
            {{ getLocationName(item) }}
          </nz-auto-option>
        </nz-autocomplete>
      </div>

      <!--     DHO filter-->
      <div class="form-item" style="width: 80px;">
        <div class="form-label-v2">{{ getLabel('dho') }}</div>
        <nz-input-group nzSize="default">
          <input nz-input type="number" [formControlName]="'dho'"/>
        </nz-input-group>
      </div>

      <!--     Swap button-->
      <div class="form-item" style="width: 50px; text-align: center;">
        <a nz-icon nzType="swap" style="margin-top: 30px;"
          (click)="onSwap()"></a>
      </div>

      <!--     Destination filter-->
      <div class="form-item flex1">
        <div class="single-line">Destination City or State
          <span *ngIf="isRequired('destination')" class="label-mark-required"></span>
        </div>
        <input #powerLaneInputTo (input)="onInputChanged($event, 'destination')"
                [nzAutocomplete]="autoComplete2"
                [placeholder]="'Enter to search'"
                [formControlName]="'destination'"
                id="input-destination"
                nz-input>
        <nz-autocomplete #autoComplete2 [compareWith]="compareAddress">
          <nz-auto-option *ngFor="let item of listAddressAutoComplete['input-destination']"
                          [nzValue]="item" [nzLabel]="getLocationName(item)">
            {{ getLocationName(item) }}
          </nz-auto-option>
        </nz-autocomplete>
      </div>

      <!--     DHD filter-->
      <div cass="form-item" style="width: 80px;">
        <div class="form-label-v2">{{ getLabel('dhd') }}</div>
        <nz-input-group nzSize="default">
          <input nz-input type="number" [formControlName]="'dhd'"/>
        </nz-input-group>
      </div>
    </div>

    <div style="display: flex; flex-wrap: wrap;padding: 20px; justify-content: space-between; gap: 8px;">
      <!--      Equipment Type-->
      <div class="form-item flex1" style="min-width: 200px;">
        <div class="form-label-v2">{{ getLabel('equipmentType') }} <span *ngIf="isRequired('equipmentType')" class="label-mark-required"></span></div>
        <nz-select [formControlName]="'equipmentType'" [nzPlaceHolder]="getPlaceHolder('equipmentType')" nzAllowClear
                   nzBackdrop="true" nzMode="multiple" style="width: 100%;" [nzOptions]="equipmentOptions">
          <!-- <nz-option *ngFor="let type of equipmentOptions" [nzLabel]="type.label" [nzValue]="type.value">
          </nz-option> -->
        </nz-select>
      </div>

      <!--      Load Type-->
      <div class="form-item" style="width: 150px;">
        <div class="form-label-v2">{{ getLabel('loadType') }}
          <span *ngIf="isRequired('loadType')" class="label-mark-required"></span>
        </div>
        <nz-select [formControlName]="'loadType'"
                   [nzPlaceHolder]="getPlaceHolder('loadType')" nzShowSearch style="width: 100%;">
          <nz-option *ngFor="let type of loadTypes" [nzLabel]="type.label"
                     [nzValue]="type.value">
          </nz-option>
        </nz-select>
      </div>

      <!--      LengthFt filter-->
      <div class="form-item" style="width: 100px;">
        <div class="form-label-v2">{{ getLabel('lengthFeet') }}</div>
        <nz-input-group nzSize="default">
          <input nz-input type="number" [formControlName]="'lengthFeet'"
                 placeholder="Feet"/>
        </nz-input-group>
      </div>

      <!--      WeightIbs filter-->
      <div class="form-item" style="width: 100px;">
        <div class="form-label-v2">{{ getLabel('weightPounds') }}</div>
        <nz-input-group nzSize="default">
          <input nz-input type="number" [formControlName]="'weightPounds'"
                 placeholder="Pounds" nzSize="default"/>
        </nz-input-group>
      </div>

      <!--      Date Range-->
      <div class="form-item">
        <div class="form-label-v2">Date Range <span *ngIf="isRequired('dateRange')" class="label-mark-required"></span></div>
        <div class="date-picker-row bottom20 flex">
          <nz-range-picker [formControlName]="'dateRange'" [nzFormat]="'yyyy/MM/dd'" [nzDisabledDate]="disabledDate" [nzAllowClear]="false"></nz-range-picker>
        </div>
      </div>

      <!--      Button Search-->
      <div class="form-item">
        <button nz-button nzType="primary" style="margin-top:22px;" (click)="doSearch()" [disabled]="formGroupError">
          <span nz-icon nzType="search"></span>
          Search
        </button>
      </div>


      <!--      more-->
      <!-- <a class="more">
        <span nz-icon nzType="more" nzTheme="outline" style="font-size: 35px; margin-top: 18px; margin-left: 10px"
              nz-dropdown nzTrigger="click" [nzDropdownMenu]="more"
              class="nz-lg"></span>
        <nz-dropdown-menu #more="nzDropdownMenu">
          <div class="filter-container">
            <div class="filter-option">
              <a class="filter-link">
                <span class="filter-icon" nz-icon nzType="close" nzTheme="outline"></span>
                Clear Filters
              </a>
            </div>
            <div class="filter-option">
              <a class="filter-link">
                <span class="filter-icon" nz-icon nzType="save" nzTheme="outline"></span>
                Save Search
              </a>
            </div>
            <div class="filter-option">
              <a class="filter-link">
                <span class="filter-icon" nz-icon nzType="search" nzTheme="outline"></span>
                New Search
              </a>
            </div>
            <div class="filter-option">
              <a class="filter-link">
                <span class="filter-icon" nz-icon nzType="file-search" nzTheme="outline"></span>
                New Post from Search
              </a>
            </div>
            <div class="filter-option">
              <div class="filter-checkbox">
                <label nz-checkbox>Hide Canadian Results</label>
              </div>
            </div>
            <div class="filter-option">
              <div class="filter-checkbox">
                <label nz-checkbox>Hide Destination:Anywhere</label>
              </div>
            </div>
            <div class="filter-option">
              <div class="filter-checkbox">
                <label nz-checkbox>Showing Realtime Results</label>
              </div>
            </div>
          </div>
        </nz-dropdown-menu>
      </a> -->
    </div>
  </div>
  <div style="width: 100%; background-color: white;">
    <div style="display: flex; flex-wrap: wrap;padding: 20px;margin-top: 20px">
      <div class="truck-detail">
        <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="truckDetail">
          TRUCK DETAILS
          <span nz-icon nzType="down"></span>
        </button>
        <nz-dropdown-menu #truckDetail="nzDropdownMenu">
          <div style="background-color: white; padding: 30px">
            <div class="title">
              <span class="black">Truck Details</span>
            </div>
            <div class="body" style="padding-top: 20px">
              <span class="bold" style="padding-top: 20px">Tracking Information</span>
              <br>
              <label
                     [formControlName]="'includeOnlyTrackingRequired'"
                     nz-checkbox>Only show trucks that are GPS tracking capable</label>
            </div>
            <div class="action" style="margin-top: 30px;margin-left: 45%;">
              <button nz-button nzType="default" style="margin-right: 10px"
                      (click)="clearSearch(['includeOnlyTrackingRequired'])">
                CLEAR ALL
              </button>
              <button nz-button nzType="primary" (click)="doSearch()" [disabled]="formGroupError">
                DONE
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
      </div>
      <div class="search-back" style="margin-left: 10px">
        <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="searchBack">
          SEARCH BACK - 24H
          <span nz-icon nzType="down"></span>
        </button>
        <nz-dropdown-menu #searchBack="nzDropdownMenu">
          <div style="background-color: white; padding: 30px">
            <div class="title">
              <span class="black">Search Back</span>
            </div>
            <div class="body" style="padding-top: 20px">
              <nz-input-group nzSize="default">
                <input nz-input type="number"
                       [formControlName]="'maxAgeHours'"/>
              </nz-input-group>
              <br>
              <span class="font-xlight">Searches back in hours from current time.</span>
            </div>
            <div class="action" style="margin-top: 30px;margin-left: 25%;">
              <button nz-button nzType="default" style="margin-right: 10px" (click)="clearSearch(['maxAgeHours'])">
                CLEAR ALL
              </button>
              <button nz-button nzType="primary" (click)="doSearch()" [disabled]="formGroupError">
                DONE
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
      </div>

      <!-- Company filters -->
      <!-- <div class="company" style="margin-left: 10px">
        <button nz-button nz-dropdown nzTrigger="click" [nzDropdownMenu]="company">
          COMPANY
          <span nz-icon nzType="down"></span>
        </button>
        <nz-dropdown-menu #company="nzDropdownMenu">
          <div style="background-color: white; padding: 30px; width: 400px">
            <div class="body">
              <div class="company-info">
                <span class="black label bottom10">Company</span>
                <br>
                <nz-radio-group [formControlName]="'isExcludeCompany'">
                  <label nz-radio nzValue="true">Show Only</label>
                  <label nz-radio nzValue="false">Exclude</label>
                </nz-radio-group>
                <div *ngFor="let key of ['companyName']" class="form-item" style="width: 300px">
                  <nz-select
                             [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" nzAllowClear
                             nzBackdrop="true"
                             nzMode="multiple" style="width: 100%">
                    <nz-option *ngFor="let company of datCompanies | keyvalue" [nzLabel]="company.value"
                               [nzValue]="company.key">
                    </nz-option>
                  </nz-select>
                </div>
              </div>
                           <div class="company-state" style="margin-top: 20px">
                             <span class="black label bottom10">Filter by Company State</span>
                             <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Company state location" style="width: 300px">
                               <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
                               <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
                               <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
                             </nz-select>
                             <br>
                             <span>The company's home location</span>
                           </div>
                           <div class="preferred-block-list" style="margin-top: 20px">
                             <span class="black label bottom10">Preferred and Blocked List</span>
                             <nz-radio-group>
                               <label nz-radio nzValue="A">Show All Non-blocked Companies</label>
                               <label nz-radio nzValue="B">Show Only Preferred Companies</label>
                               <label nz-radio nzValue="C">Show Only Blocked Companies</label>
                             </nz-radio-group>
                             <span class="bold top10">MANAGE PREFERRED AND BLOCKED LISTS</span>
                           </div>
            </div>
            <div class="action" style="margin-top: 30px;margin-left: 30%;">
              <button nz-button nzType="default" style="margin-right: 10px"
                      (click)="clearSearch(['isExcludeCompany', 'companyName'])">
                CLEAR ALL
              </button>
              <button nz-button nzType="primary" (click)="doSearch()" [disabled]="formGroupError">
                DONE
              </button>
            </div>
          </div>
        </nz-dropdown-menu>
      </div> -->
    </div>
  </div>
</form>
