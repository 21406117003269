import { Component, Input } from '@angular/core';
import { BaseDialog } from '@dialogs/base-dlg';

@Component({
  selector: '[quickbooks-bill-created-successfully]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss' ]
})
export class QuickbooksBillCreateSuccessfully extends BaseDialog {

  @Input() model;

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }

}