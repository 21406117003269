<div class="bottom10" *ngIf="modelOrder">
  Order: <span class="warp-id left5">{{showOrderCode(modelOrder)}}</span>
</div>

<form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput" nzLayout="vertical">
  <div *ngIf="'refNum' as key">
    <nz-form-item>
      <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
      <nz-form-control>
        <input nz-input [placeholder]="getPlaceHolder(key)"
          (input)="onInputChanged($event, key)"
          [formControlName]="key"/>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>
<div *ngIf="alreadySearch" class="bottom20">
  <div class="medium top10 bottom5">{{ listOfData.length }} {{ listOfData.length > 1 ? 'results' : 'result' }}</div>
  <div>
    <nz-table 
      #nzTable nzBordered="true"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzData]="listOfData" nzSize="small"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="10"
      [nzNoResult]="tplNoData">
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <ng-template #tplNoData>
        <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <thead>
        <tr>
          <th>Job / PO #</th>
          <th>Job status</th>
          <th>Job Link</th>
          <th nzWidth="250px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>
            {{ item.reference_number }}
          </td>
          <td>
            <span class="job-status">{{ item.status }}</span>
          </td>
          <td>
            <a href="{{item.denimJobLink}}" target="_blank">Detail</a>
          </td>
          <td>
            <div *ngIf="listOfData.length == 1 && item.uuid">
              <button nz-button (click)="onBtnAssignJobToStatement(item)">Assign job to this statement</button>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && !isLoading" [canClickCancel]="!onProgress" labelOK="Search" labelCancel="Close"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
