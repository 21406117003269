import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { FinPayableBatchListFilter } from '.';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzBadgeModule,
    NzButtonModule,
    NzCheckboxModule,
    NzSelectModule,
    NzDatePickerModule,
    NzIconModule,
    SearchBoxModule,
    CommonModule,
    FormsModule,
    NzInputModule,
    NzIconModule
  ],
  declarations: [FinPayableBatchListFilter],
  exports: [FinPayableBatchListFilter]
})
export class ModuleFinPayableBatchListFilter {
}