<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Create Denim Job From Statement {{ displayInfo.code ?? '' }}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div class="f15 bold">Statement Information</div>
<div nz-row [nzGutter]="16">
  <div nz-col nzSpan="12" style="border-right: 1px solid #EBEBEB;">
    <div class="text-center f15 medium bottom10">Receivables</div>
    <div *ngIf="!displayInfo.receivableGroups?.length">
      <div class="nodata-statement">No Data</div>
    </div>
    <div *ngFor="let group of displayInfo.receivableGroups || []">
      <div class="flex medium f14 bottom10">
        <div>Customer: </div>
        <div class="left5">
          <a target="_blank" class="link" [routerLink]="routerLinkAccount(group.account, 'AR')">{{ group.name }}</a>
        </div>
      </div>
      <ng-container *ngFor="let child of group.childrens || []">
        <div class="box-child">
          <div class="batch" *ngIf="child.batch?.id">
            <div class="label">Batch</div>
            <a [routerLink]="getBatchRouterLink(child.batch, 'AR')" target="_blank">{{ showBatchCode(child.batch, 'AR')}}</a>
          </div>
          <div class="biz-entity">
            <div class="label">Order</div>
            <a target="_blank" [routerLink]="routerLinkBizEntity(child.relatedBiz, 'AR')">{{child.relatedBiz.code}}</a>
          </div>
          <div *ngIf="child.invoiceFiles?.length" class="biz-entity">
            <div class="label">Invoice</div>
            <div>
              <div *ngFor="let invoice of child.invoiceFiles">
                <a (click)="downloadAttachedFile(invoice)">
                  <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                    class="right10"></i>
                  <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                    class="right10"></i>{{ invoice.name ?? 'Download Invoice' }}
                </a>
              </div>
            </div>
          </div>
          <div class="cost">
            <div class="label">Cost</div>
            <div class="flex1">
              <div class="flex-space-between">
                <div>Amount:</div>
                <div>${{ child.cost?.amt ?? 0}}</div>
              </div>
              <div>Services:</div>
              <div *ngFor="let service of child.cost?.services || []" class="flex-space-between">
                <div>{{ service?.name }}</div>
                <div>{{ service?.negative ? '-' : '' }} $ {{ service?.total ?? 0}}</div>
              </div>
              <div class="flex-space-between">
                <div>GrandTotal:</div>
                <div>${{ child.cost?.grandTotal ?? 0}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div nz-col nzSpan="12">
    <div class="text-center f15 medium bottom10">Payables</div>
    <ng-container *ngIf="!displayInfo.isFixedRate">
      <div *ngIf="!displayInfo.payableGroups?.length">
        <div class="nodata-statement">No Data</div>
      </div>
      <div *ngFor="let group of displayInfo.payableGroups || []">
        <div class="flex medium f14 bottom10">
          <div>Carrier: </div>
          <div class="left5">
            <a target="_blank" class="link" [routerLink]="routerLinkAccount(group.account, 'AP')">{{ group.name }}</a>
            <i *ngIf="group.account.mc" class="view-mc-dot"> (MC: {{group.account.mc}})</i>
          </div>
        </div>
        <ng-container *ngFor="let child of group.childrens || []">
          <div class="box-child">
            <div class="batch">
              <div class="label">Batch</div>
              <a [routerLink]="getBatchRouterLink(child.batch, 'AP')" target="_blank">{{ showBatchCode(child.batch, 'AP')}}</a>
            </div>
            <div class="biz-entity">
              <div class="label">Route</div>
              <a target="_blank" [routerLink]="routerLinkBizEntity(child.relatedBiz, 'AP')">{{child.relatedBiz.code}}</a>
            </div>
            <div *ngIf="child?.invoiceFiles?.length" class="biz-entity">
              <div class="label">Invoice</div>
              <div>
                <div *ngFor="let invoice of child.invoiceFiles">
                  <a (click)="downloadAttachedFile(invoice)">
                    <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                      class="right10"></i>
                    <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                      class="right10"></i>{{ invoice.name ?? 'Download Invoice' }}
                  </a>
                </div>
              </div>
            </div>
            <div class="cost">
              <div class="label">Cost</div>
              <div class="flex1">
                <div class="flex-space-between">
                  <div>Amount:</div>
                  <div>${{ child.cost?.amt ?? 0}}</div>
                </div>
                <div>Services:</div>
                <div *ngFor="let service of child.cost?.services || []" class="flex-space-between">
                  <div>{{ service?.name }}</div>
                  <div>{{ service?.negative ? '-' : '' }}${{ service?.total ?? 0}}</div>
                </div>
                <div class="flex-space-between">
                  <div>GrandTotal:</div>
                  <div>${{ child.cost?.grandTotal ?? 0}}</div>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let finJob of child?.finJobs ?? []">
              <div *ngIf="finJob?.quickbooksBillId" class="cost">
                <div class="label">Quickbooks</div>
                <div class="flex1">
                  <div class="flex-space-between">
                    <div>Bill no.</div>
                    <div>
                      <a [href]="getQBBillLink(finJob?.quickbooksBillId)" target="_blank" nz-tooltip="Go to Quickbooks">{{ finJob?.quickbooksBillNo ?? 'here' }}</a>
                      <i nz-icon nzType="copy" nzTheme="outline" class="copy clickable" style="padding: 8px;" (click)="copyQuickbookBillNo(finJob?.quickbooksBillNo)"></i>
                    </div>
                  </div>
                  <ng-container *ngIf="finJob?.quickbooksDetailInfo">
                    <div class="flex-space-between">
                      <div>Payment terms</div>
                      <div>{{ finJob?.quickbooksDetailInfo?.paymentTerms ?? 'N/A' }}</div>
                    </div>
                    <div>Category:</div>
                    <div class="flex-space-between" *ngFor="let line of finJob?.quickbooksDetailInfo?.lines ?? []">
                      <div class="left20">{{ line.name }}</div>
                      <div>{{ line.amount ?? 'N/A' }}</div>
                    </div>
                    <div class="flex-space-between">
                      <div>Payable amount:</div>
                      <div class="medium f16">{{ finJob?.quickbooksDetailInfo?.balance ?? 'N/A' }}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="displayInfo.isFixedRate && displayInfo.fixedRateInfo?.account">
      <div class="flex medium f14 bottom10">
        <div>Carrier: </div>
        <div class="left5">
          <a target="_blank" class="link" [routerLink]="routerLinkAccount(displayInfo.fixedRateInfo.account, 'AP')">{{ displayInfo.fixedRateInfo.account.name }}</a>
        </div>
      </div>
      <div class="box-child">
        <div class="biz-entity">
          <div>Payment Fixed Rate:</div>
          <div class="bold left5">{{ displayInfo.fixedRateInfo.paymentFixedRate }}</div>
        </div>
        <div class="biz-entity">
          Working Days({{displayInfo.fixedRateInfo.numOfWorkingDays}} days) : {{ displayInfo.fixedRateInfo?.workingDays?.join(', ') || 'N/A' }}
        </div>
        <div *ngIf="displayInfo.fixedRateInfo.invoiceFiles?.length" class="biz-entity bottom5">
          <div class="label">Invoice</div>
          <div>
            <div *ngFor="let invoice of displayInfo.fixedRateInfo.invoiceFiles">
              <a (click)="downloadAttachedFile(invoice)">
                <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                  class="right10"></i>
                <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                  class="right10"></i>{{ invoice.name ?? 'Download Invoice' }}
              </a>
            </div>
          </div>
        </div>
        <div class="cost">
          <div class="label">Cost</div>
          <div class="flex1">
            <div class="flex-space-between">
              <div>Amount:</div>
              <div>${{ displayInfo.fixedRateInfo.cost?.amt ?? 0}}</div>
            </div>
            <div>Services:</div>
            <div *ngFor="let service of displayInfo.fixedRateInfo.cost?.services || []" class="flex-space-between">
              <div>{{ service?.name }}</div>
              <div>{{ service?.negative ? '-' : '' }}${{ service?.total ?? 0}}</div>
            </div>
            <div class="flex-space-between">
              <div>GrandTotal:</div>
              <div>${{ displayInfo.fixedRateInfo.cost?.grandTotal ?? 0}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="f15 bold">Denim Job Form</div>
<form *ngIf="formInput" class="form-detail" [formGroup]="formInput" nz-form>
  <div nz-row [nzGutter]="16">
    <div nz-col nzMd="12" style="border-right: 1px solid #EBEBEB;">
      <div *ngIf="'reference_number' as key">
        <div>
          {{getLabel(key)}}
          <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key"
              [type]="getInputType(key)"
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)"
              (keypress)="onInputKeyPress($event, key)" >
          </nz-form-control>
        </nz-form-item>
      </div>
      <div *ngIf="'is_factored' as key">
        <nz-form-item>
          <nz-form-control>
            <nz-radio-group nzBackdrop="true" nzButtonStyle="solid" [formControlName]="key">
              <label nz-radio-button [nzValue]="true">FACTORED</label>
              <label nz-radio-button [nzValue]="false">NON-FACTORED</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-col nzMd="12">
      <div *ngIf="'notes' as key">
        <div>{{getLabel(key)}}</div>
        <nz-form-item>
          <nz-form-control>
            <textarea nz-input [placeholder]="getPlaceHolder(key)"
              [nzAutosize]="{ minRows: 3, maxRows: 6 }"
              [formControlName]="key"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div>
        <label nz-checkbox [(ngModel)]="isSkipCarrierInvoice" [ngModelOptions]="{standalone: true}"
          (ngModelChange)="onCheckboxCarrierInvoiceChanged()">Carrier invoice unavailable now
        </label>
      </div>
    </div>
  </div>
  <div nz-row [nzGutter]="16" class="top10">
    <div nz-col nzSpan="12" style="border-right: 1px solid #EBEBEB;">
      <div *ngFor="let groupKey of ['debtor_relationships']" [formArrayName]="groupKey">
        <div class="text-center f15 medium bottom10">{{ getLabel(groupKey) }}</div>
        <form *ngFor="let item of getArrayControls(groupKey); let relationshipIndex = index" nz-form [formGroupName]="relationshipIndex" class="box-child">
          <div *ngIf="'finAccountName' as key" class="flex bottom10">
            <div class="w170">{{ getLabel(fullKey(groupKey, key)) }}</div>
            <div class="medium">{{ getFinAccountName(groupKey, relationshipIndex, key) }}</div>
          </div>
          <div *ngIf="'relationship_id' as key" class="flex bottom10">
            <div class="w170">
              {{ getLabel(fullKey(groupKey, key)) }}
              <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
            </div>
            <div class="flex1">
              <div *ngIf="hasRelationshipId(groupKey, relationshipIndex, key)">
                <span>{{ getCompanyName(groupKey, relationshipIndex) }}</span>
                <span 
                  nz-icon 
                  nzType="eye" 
                  nzTheme="outline" 
                  nz-popover 
                  [nzPopoverContent]="tooltipCompanyInfo" 
                  style="font-size: 20px; margin-left: 10px; position: relative; top: 2px; cursor: pointer;">
                </span>
                <ng-template #tooltipCompanyInfo>
                  <ng-container
                    [ngTemplateOutletContext]="{data: getCompanyInfo(groupKey, relationshipIndex)}"
                    [ngTemplateOutlet]="tplCompanyInfoInner">
                  </ng-container>
                  <ng-template #tplCompanyInfoInner let-data="data">
                    <nz-descriptions  nzBordered [nzColumn]="1" nzSize="small">
                      <nz-descriptions-item nzTitle="Company name">
                          {{ data.company_name || 'N/A' }}
                      </nz-descriptions-item>
                      <nz-descriptions-item nzTitle="EIN">
                        {{ data.ein || 'N/A' }}
                      </nz-descriptions-item>
                      <nz-descriptions-item nzTitle="MC Number">
                        {{ data.mc_number || 'N/A' }}
                      </nz-descriptions-item>
                      <nz-descriptions-item nzTitle="Address">
                        {{ data.fullAddress || 'N/A' }}
                      </nz-descriptions-item>
                  </nz-descriptions>
                  </ng-template>
                </ng-template>
              </div>
              <div *ngIf="!hasRelationshipId(groupKey, relationshipIndex, key)">
                <div style="color: red;">Not found</div>
              </div>
            </div>
            <div class="flex">
              <ng-container *ngIf="!isVerifiedRelationship(groupKey, relationshipIndex)">
                <button nz-button nzSize="small" (click)="onBtnSearchCompany('debtor', groupKey, relationshipIndex)">
                  {{ getLabelSearchCompany(groupKey, relationshipIndex, key) }}
                </button>
                <button *ngIf="hasRelationshipId(groupKey, relationshipIndex, key)" 
                  nz-button nzSize="small" 
                  nzType="primary" class="left10" (click)="onBtnConfirmRelationship(groupKey, relationshipIndex)"
                >Confirm</button>
              </ng-container>
              <ng-container *ngIf="isVerifiedRelationship(groupKey, relationshipIndex)">
                <div class="verified-relationship">
                  <span nz-icon nzType="check-circle" nzTheme="outline" style="margin-right: 2px;"></span>Confirmed
                </div>
                <button (click)="undoVerifiedRelationship(groupKey, relationshipIndex)" 
                  class="left5" nzSize="small"
                  nz-tooltip nzTooltipPlacement="right" nzTooltipTitle="Undo confirm"
                  nz-button nzType="text">
                  <i nz-icon nzType="undo"></i>Undo
                </button>
              </ng-container>
            </div>
          </div>
          <div *ngFor="let key of ['reference_number', 'base_amount']" class="flex">
            <div class="w170">
              {{ getLabel(fullKey(groupKey, key)) }}
              <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
            </div>
            <nz-form-item style="width: 100%;">
              <nz-form-control>
                <input nz-input [formControlName]="key"
                  [type]="getInputType(fullKey(groupKey, key))"
                  [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                  (input)="onInputChanged($event, fullKey(groupKey, key), relationshipIndex)"
                  (keypress)="onInputKeyPress($event, fullKey(groupKey, key))"
                  (focusout)="onInputFocusOut($event, fullKey(groupKey, key))">
              </nz-form-control>
            </nz-form-item>
          </div>
          <div *ngFor="let key of ['obligation_date', 'due_date']" class="flex">
            <div class="w170">
              {{ getLabel(fullKey(groupKey, key)) }}
              <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
            </div>
            <nz-form-item>
              <nz-form-control>
                <nz-input-group [nzAddOnAfter]="shortTimezone">
                  <nz-date-picker nz-input
                    [formControlName]="key"
                    nzFormat="yyyy-MM-dd HH:mm"
                    [nzDisabledDate]="disabledDate"
                    [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
                  ></nz-date-picker>
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div>
            Billing Documents: system will automatically upload invoice file to denim.
          </div>
        </form>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <div *ngFor="let groupKey of ['payee_relationships']" [formArrayName]="groupKey">
        <div class="text-center f15 medium bottom10">{{ getLabel(groupKey) }}</div>
        <div *ngIf="!getArrayControls(groupKey).length" class="top10 nodata-statement">No Contractor</div>
        <form *ngFor="let item of getArrayControls(groupKey); let relationshipIndex = index" nz-form [formGroupName]="relationshipIndex" class="box-child">
          <div nz-row [nzGutter]="8">
            <div nz-col nzSpan="16">
              <div *ngIf="'finAccountName' as key" class="flex bottom10">
                <div class="w170">{{ getLabel(fullKey(groupKey, key)) }}</div>
                <div class="medium">{{ getFinAccountName(groupKey, relationshipIndex, key) }}</div>
                <div *ngIf="getFinAccountMcNumber(groupKey, relationshipIndex)" class="view-mc-dot">{{ getFinAccountMcNumber(groupKey, relationshipIndex) }}</div>
              </div>
              <div *ngIf="'relationship_id' as key" class="flex bottom10">
                <div class="w170">
                  {{ getLabel(fullKey(groupKey, key)) }}
                  <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                </div>
                <div class="flex1">
                  <div *ngIf="hasRelationshipId(groupKey, relationshipIndex, key)">
                    <span>{{ getCompanyName(groupKey, relationshipIndex) }}</span>
                    <span 
                      nz-icon 
                      nzType="eye" 
                      nzTheme="outline" 
                      nz-popover 
                      [nzPopoverContent]="tooltipCompanyInfo" 
                      style="font-size: 20px; margin-left: 10px; position: relative; top: 2px; cursor: pointer;">
                    </span>
                    <ng-template #tooltipCompanyInfo>
                      <ng-container
                        [ngTemplateOutletContext]="{data: getCompanyInfo(groupKey, relationshipIndex)}"
                        [ngTemplateOutlet]="tplCompanyInfoInner">
                      </ng-container>
                      <ng-template #tplCompanyInfoInner let-data="data">
                        <nz-descriptions  nzBordered [nzColumn]="1" nzSize="small">
                          <nz-descriptions-item nzTitle="Company name">
                              {{ data.company_name || 'N/A' }}
                          </nz-descriptions-item>
                          <nz-descriptions-item nzTitle="EIN">
                            {{ data.ein || 'N/A' }}
                          </nz-descriptions-item>
                          <nz-descriptions-item nzTitle="MC Number">
                            {{ data.mc_number || 'N/A' }}
                          </nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Address">
                            {{ data.fullAddress || 'N/A' }}
                          </nz-descriptions-item>
                      </nz-descriptions>
                      </ng-template>
                    </ng-template>
                  </div>
                  <div *ngIf="!hasRelationshipId(groupKey, relationshipIndex, key)">
                    <div style="color: red;">Not found</div>
                  </div>
                </div>
                <div class="flex">
                  <ng-container *ngIf="!hasRelationshipId(groupKey, relationshipIndex, key)" >
                    <button nz-button nzSize="small" (click)="onBtnSearchCompany('payee', groupKey, relationshipIndex)">
                      {{ getLabelSearchCompany(groupKey, relationshipIndex, key) }}
                    </button>
                  </ng-container>
                  <ng-container *ngIf="hasRelationshipId(groupKey, relationshipIndex, key)" >
                    <div>
                      <ng-container *ngIf="!isVerifiedRelationship(groupKey, relationshipIndex)">
                        <button
                          nz-button nzSize="small" 
                          nzType="primary" class="left10" (click)="onBtnConfirmRelationship(groupKey, relationshipIndex)"
                        >Confirm</button>
                      </ng-container>
                      <ng-container *ngIf="isVerifiedRelationship(groupKey, relationshipIndex)">
                        <div class="verified-relationship">
                          <span nz-icon nzType="check-circle" nzTheme="outline" style="margin-right: 2px;"></span>Confirmed
                        </div>
                      </ng-container>
                    </div>
                    <div class="left5">
                      <button nz-button nzSize="small" nz-dropdown [nzDropdownMenu]="menuRelationship" nzPlacement="bottomRight">
                        <span nz-icon nzType="ellipsis"></span>
                      </button>
                      <nz-dropdown-menu #menuRelationship="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item *ngIf="!isVerifiedRelationship(groupKey, relationshipIndex)">
                            <div (click)="onBtnSearchCompany('payee', groupKey, relationshipIndex)">
                              {{ getLabelSearchCompany(groupKey, relationshipIndex, key) }} Carrier
                            </div>
                          </li>
                          <li nz-menu-item *ngIf="isVerifiedRelationship(groupKey, relationshipIndex)">
                            <div (click)="undoVerifiedRelationship(groupKey, relationshipIndex)">
                              <i nz-icon nzType="undo"></i>
                              <span class="left5">Undo confirm</span>
                            </div>
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div *ngFor="let key of ['reference_number']" class="flex">
                <div class="w170">
                  {{ getLabel(fullKey(groupKey, key)) }}
                  <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                </div>
                <nz-form-item style="width: 100%;">
                  <nz-form-control>
                    <input nz-input [formControlName]="key"
                      [type]="getInputType(fullKey(groupKey, key))"
                      [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                      (input)="onInputChanged($event, fullKey(groupKey, key), relationshipIndex)"
                      (keypress)="onInputKeyPress($event, fullKey(groupKey, key))"
                      (focusout)="onInputFocusOut($event, fullKey(groupKey, key))">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div *ngIf="'paymentTerms' as key" class="flex">
                <div class="w170">
                  {{ getLabel(fullKey(groupKey, key)) }}
                  <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                </div>
                <div style="width: 100%;">
                  <app-form-carrier-payment-term [formControlName]="key" (ngModelChange)="onPaymentTermsChange(groupKey, relationshipIndex, $event)"></app-form-carrier-payment-term>
                </div>
              </div>
              <div *ngFor="let key of ['obligation_date', 'due_date']" class="flex">
                <div class="w170">
                  {{ getLabel(fullKey(groupKey, key)) }}
                  <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                </div>
                <nz-form-item>
                  <nz-form-control>
                    <nz-input-group [nzAddOnAfter]="shortTimezone">
                      <nz-date-picker nz-input
                        [formControlName]="key"
                        nzFormat="yyyy-MM-dd HH:mm"
                        [nzDisabledDate]="key == 'obligation_date' ? disabled30DayAgoDate : disabledDate"
                        (ngModelChange)="key == 'obligation_date' ? onInvoiceDateChange(groupKey, relationshipIndex, $event) : null"
                        [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
                      ></nz-date-picker>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div *ngFor="let key of ['attachmentFile']" class="bottom15">
                <input #inputFile type="file" hidden accept=".png,.jpg,.jpeg,.pdf" (change)="onDocumentFileSelected(groupKey, relationshipIndex, key, inputFile.files)">
                <div *ngIf="!getDocumentAttachedFileValue(groupKey, relationshipIndex, key)?.length" class="flex">
                  <div class="w170">
                    <div class="form-label-v2">{{getLabel(fullKey(groupKey, key))}}<span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span></div>
                  </div>
                  <div>
                    <button nz-button nzType="default" (click)="inputFile.click()">
                      <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
                    </button>
                  </div>
                </div>
                <ng-container *ngIf="getDocumentAttachedFileValue(groupKey, relationshipIndex, key).length">
                  <div class="flex">
                    <div class="w170">
                      <div class="form-label-v2">{{getLabel(fullKey(groupKey, key))}}<span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span></div>
                    </div>
                    <div>
                      <button nz-button nzType="default" (click)="inputFile.click()">
                        <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
                      </button>
                    </div>
                  </div>
                  <ng-container *ngFor="let valueKey of getDocumentAttachedFileValue(groupKey, relationshipIndex, key)">
                    <div *ngIf="hasDocumentAttachedFile(valueKey)">
                      <div class="flex-space-between" style="align-items: center;">
                        <div class="file-selected">
                          <i nz-icon nzType="paper-clip" nzTheme="outline"></i>{{ labelSelectFile(valueKey) }}
                        </div>
                        <button nz-button nzType="text" (click)="delDocumentFile(groupKey, relationshipIndex, key, valueKey, inputFile)">
                          <i nz-icon nzType="delete"></i>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div nz-col nzSpan="8">
              <div style="border: 1px solid #EBEBEB; padding: 10px;">
                <div class="flex-space-between bottom5 medium">
                  <div>Carrier cost</div>
                  <div> {{ getCarrierCost(groupKey, relationshipIndex) }}</div>
                </div>
                <div class="flex-space-between bottom5">
                  <div>{{ getLabelPaymentFee(groupKey, relationshipIndex) }}</div>
                  <div>{{ getValuePaymentFee(groupKey, relationshipIndex) }}</div>
                </div>
                <nz-divider></nz-divider>
                <div class="flex-space-between medium" [ngClass]="{ 'red' : checkPayableAmoutMatchQB(relationshipIndex) === -1 , 'green': checkPayableAmoutMatchQB(relationshipIndex) === 1 }">
                  <div>Payable amount</div>
                  <div style="font-size: 18px;">
                    {{ getPayableAmount(groupKey, relationshipIndex) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="top20 bottom20 flex" style="flex-direction: row-reverse;" >
    <button 
      nz-button 
      nzType="primary" 
      [nzLoading]="onProgress"
      [disabled]="!needUpdate || onProgress"
      (click)="onBtnCreateJob()"
    >Create Draft Job</button>
  </div>
</form>

