<div class=" flex">
  <div *ngIf="!shouldShowBatchHyperLink" class="text-batch-code bottom15">
    Batch {{showBatchCode()}}
  </div>
  <div *ngIf="shouldShowBatchHyperLink" class="text-batch-code bottom15">
    <a [routerLink]="getRouterLink()" target="_blank" class="link">Batch {{showBatchCode()}}</a>
  </div>
  <ng-container *ngIf="isAR && isReadyToProcess && !isClientStatementBill && !data.statement?.id">
    <button *ngIf="listInvoiceFiles?.length" nz-button nzSize="small" (click)="onBtnCreateStatement()" class="f13 left20">Create Statement</button>
    <button *ngIf="!listInvoiceFiles?.length" nz-button nzSize="small" disabled nz-tooltip="Not generate invoice yet" class="f13 left20">Create Statement</button>
    </ng-container>
</div>

<ng-container *ngIf="isAR">
  <div class="biz-entity">
    <div class="label">Order</div>
    <a target="_blank" [routerLink]="routerLinkBizEntity()">{{data.relatedBiz.code}}</a>
  </div>
  <div class="account top10">
    <div class="label">Customer</div>
    <a target="_blank" class="link" [routerLink]="routerLinkAccount()">{{data.account.name}}</a>
  </div>
  <div *ngIf="data.statement?.id" class="biz-entity top10">
    <div class="label">Statement</div>
    <a (click)="onBtnViewStatementDetail()">ST-{{ data.statement.code }}</a>
  </div>
</ng-container>

<ng-container *ngIf="isAP">
  <div class="biz-entity">
    <div class="label">Route</div>
    <a target="_blank" [routerLink]="routerLinkBizEntity()">{{data.relatedBiz.code}}</a>
  </div>
  <div class="account top10">
    <div class="label">Carrier</div>
    <a target="_blank" class="link" [routerLink]="routerLinkAccount()">{{data.account.name}}</a>
  </div>
  <div class="document top10">
    <div class="label">Documents</div>
    <file-tool [file]="data?.relatedBiz?.metadata?.loadTenderFile" [fileName]="'Load Tender'"></file-tool>
  </div>
</ng-container>

<div *ngFor="let item of listFinJobs; let i=index" class="top15">
  <div batch-fin-job [finJob]="item" [finAccountId]="data.finAccountId" [finType]="data.finType" [parentFinJob]="item.parent" [index]="i"></div>
</div>
