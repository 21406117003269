<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Create Statement</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div nz-row nzGutter="16" class="bottom5">
      <div nz-col nzSm="6">From {{ isComeFromReceivable ? 'Receivable' : 'Payable' }} Batch</div>
      <div nz-col nzSm="18">{{ displayInfo.batchCode }}</div>
    </div>
    <div nz-row nzGutter="16" class="bottom5">
      <div nz-col nzSm="6">{{ isComeFromReceivable ? 'Order' : 'Route' }}</div>
      <div nz-col nzSm="18">{{ displayInfo.bizCode }}</div>
    </div>
    <div nz-row nzGutter="16" class="bottom5">
      <div nz-col nzSm="6">{{ isComeFromReceivable ? 'Customer' : 'Carrier' }}</div>
      <div nz-col nzSm="18">{{ displayInfo.accountName }}</div>
    </div>
    <ng-container *ngIf="isComeFromReceivable">
      <div nz-row nzGutter="16" class="bottom5">
        <div nz-col nzSm="6">Invoice</div>
        <div nz-col nzSm="18">
          <div *ngIf="!displayInfo.invoiceFiles?.length">N/A</div>
          <ng-container *ngIf="displayInfo.invoiceFiles?.length">
            <div *ngFor="let invoice of displayInfo.invoiceFiles">
              <a (click)="downloadAttachedFile(invoice)">
                <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                  class="right10"></i>
                <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                  class="right10"></i>{{ invoice.name ?? 'Download Invoice' }}
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div nz-row nzGutter="16" class="bottom5">
      <div nz-col nzSm="6">Cost</div>
      <div nz-col nzSm="18">{{ displayInfo.cost }}</div>
    </div>
    <!-- other fields -->
    <div *ngFor="let key of ['isIncludingRelatedBatches']" class="flex top20">
      <div class="form-label-v2 fix-height single-line">{{getLabel(key)}}</div>
      <div nz-checkbox [formControlName]="key" (ngModelChange)="onCheckboxChanged($event)" style="margin-left: 10px;"></div>
    </div>
    <div *ngIf="isShowRelatedBatches">
      <div *ngFor="let batch of relatedBatches || []" class="top10">
        <div nz-row nzGutter="16" class="bottom5">
          <div nz-col nzSm="6">Related {{ isComeFromReceivable ? 'Payable' : 'Receivable' }} Batch</div>
          <div nz-col nzSm="18">{{ showRelatedBatchCode(batch.code) }}</div>
        </div>
        <div nz-row nzGutter="16" class="bottom5">
          <div nz-col nzSm="6">{{ isComeFromReceivable ? 'Route' : 'Order' }}</div>
          <div nz-col nzSm="18">{{ batch.relatedBiz?.code }}</div>
        </div>
        <div nz-row nzGutter="16" class="bottom5">
          <div nz-col nzSm="6">{{ isComeFromReceivable ? 'Carrier' : 'Customer'}}</div>
          <div nz-col nzSm="18">{{ batch.account?.name}}</div>
        </div>
        <div nz-row nzGutter="16" class="bottom5">
          <div nz-col nzSm="6">Cost</div>
          <div nz-col nzSm="18">${{ batch.amt }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="'proofOfPaymentFiles' as key" class="top20">
      <div nz-row nzGutter="16" class="bottom5">
        <div nz-col nzSm="6">
          <div class="form-label-v2 fix-height single-line">{{getLabel(key)}}</div>
        </div>
        <div nz-col nzSm="18">
          <form-input-upload-images #uploadImagesForm [visible]="true" (fileSelectedChange)="onFileImageSelectedChange($event)"></form-input-upload-images>
        </div>
      </div>
    </div>
    <div *ngFor="let key of ['paymentServiceName']" class="top10">
      <div nz-row nzGutter="16" class="bottom5">
        <div nz-col nzSm="6">
          <div class="form-label-v2 fix-height single-line right10">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
        </div>
        <div nz-col nzSm="18">
          <nz-form-item>
            <nz-form-control>
              <nz-select [formControlName]="key" style="width: 150px;">
                <nz-option *ngFor="let option of listOfPaymentService" [nzLabel]="option" [nzValue]="option">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isSelectdPaymentServiceDenim">
      <form nz-form *ngFor="let key of ['paymentServiceDenim']" [formGroupName]="key">
        <div nz-row nzGutter="16">
          <div nz-col nzSm="6">
            <div class="form-label-v2 fix-height single-line right10">
              {{getLabel(fullKey(key, 'refs'))}}
              <span *ngIf="isRequired(fullKey(key, 'refs'))" class="label-mark-required"></span>
            </div>
          </div>
          <div nz-col nzSm="18">
            <ng-container *ngIf="'refs' as subKey" [formArrayName]="subKey">
              <div *ngFor="let f of getArrayControls(fullKey(key, subKey)); let arrIndex = index" style="display: flex;">
                <input nz-input [formControlName]="arrIndex" style="width: 300px; margin-bottom: 5px;">
                <div *ngIf="shouldShowRemoveDenimReference">
                  <a (click)="onBtnRemoveDenimReference(arrIndex)" class="danger left10">
                    <i nz-icon nzType="close-square" nzTheme="outline"></i> Remove
                  </a>
                </div>
              </div>
            </ng-container>
            <button nz-button nzType="dashed" (click)="onBtnAddRef()">
              <span nz-icon nzType="plus" nzTheme="outline"></span>
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Create" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>