import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Utils } from "@services/utils";
import { Observable } from "rxjs";

@Component({
  selector: 'search-denim-job-input',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class SearchDenimJobInput extends BaseFormDialog1 {

    public isLoading = false;
    public listOfData: any[] = [];
    public alreadySearch: boolean = false;
    public modelOrder: any;

    @Input() onSearch: (data) => Observable<any>;
    @Input() onAssign: (jobId) => Observable<any>;
    @Input() onRefresh: (resp) => void;

    protected formGroupDeclaration: FormGroupDeclaration = {
      refNum: { label: "Denim Job / PO #", required: true }
    };

    ngOnInit(): void {
      super.ngOnInit();
      this.getDataOrder();
    }

    private getDataOrder() {
      if (this.model?.orderId) {
        this.api.GET(`v2/orders/${this.model?.orderId}`).subscribe(
          resp => {
            if (resp?.data) {
              this.modelOrder = resp?.data
            }
          }, err => {
            this.showErr(err);
          }
        )
      }
    }

    onBtnSave() {
      if (!this.needUpdate) return;
      const refNum = this.getItemValue('refNum');
      this.onProgress = true;
      this.onSearch(refNum).subscribe(
        resp => {
          if (Utils.isArray(resp?.data?.list_data)) {
            this.alreadySearch = true;
            this.listOfData = resp.data.list_data;
          }
          this.onProgress = false;
        }, err => {
          this.showErr(err);
          this.onProgress = false;
        }
      );
    }

    onBtnAssignJobToStatement(item) {
      this.confirmYesNo(`Are you sure you want to assign?`, () => {
        let params = {
          jobId: item.uuid,
          reference_number: item.reference_number,
        }
        this.onAssign(params).subscribe(
          resp => {
            this.onRefresh(resp);
            this.closeDialog();
            this.onProgress = false;
          }, err => {
            this.showErr(err);
            this.onProgress = false;
          }
        );
      })
    }
    
  }
