import { Component } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Const } from '@const/Const';
import { Utils } from '@services/utils';
import { ResponseQuickbooksBill } from '@wearewarp/types/data-model';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[input-search-qb-bill]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss'],

})
export class InputSearchQBBill extends BaseComponent {
  public placeHolder = 'Enter shipment id or code';
  public isLoading: boolean = false;
  visible = false;
  searchKeyword;
  searchControl: FormControl = new FormControl();
  listOfData: any[] = [];

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300), // Wait for 300ms pause in events
        distinctUntilChanged() // Only emit if value is different from previous value
      )
      .subscribe(value => {
        if (value) { this.visible = true; }
        this.searchKeyword = value;
        this.onSearch(value);
      });
  }

  onSearch(value: string): void {
    const params = {
      query: value,
    }
    this.isLoading = true;
    let url = Const.APIV2(`${Const.APIURI_FINANCES}/quickbooks/search-bill`);
    url = Utils.appendQueryStringIntoUrl(url, params);
    this.api.GET(url).subscribe(
      resp => {
        let _data = resp?.data?.list_data ?? [];
        _data = _data.filter(item => item.PrivateNote?.includes(this.searchKeyword));
        this.buildDisplayInfo(_data);
        this.listOfData = _data;
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private buildDisplayInfo(items: ResponseQuickbooksBill[]) {
    for (let item of items) {
      let searchResultHtml = '';
      let note = item.PrivateNote;
      if (note && this.searchKeyword) {
        let searchKey = this.searchKeyword;
        let index = note.indexOf(searchKey);
        if (index !== -1) {
          let before = note.substring(Math.max(0, index - 14), index);
          let after = note.substring(index + searchKey.length, index + searchKey.length + 14);
          searchResultHtml = `...${before}<span class="highlight">${searchKey}</span>${after}...`;
        }
      }
      item['displayInfo'] = {
        billNo: item.DocNumber,
        amount: this.formatMoney(item.TotalAmt ?? 0),
        searchResultHtml,
      }
    }
  }

  formatMoney(value: number | string) {
    return InputHelper.formatMoney2(`${value || 0}`);
  }

  onBtnOpenBill(item: ResponseQuickbooksBill) {
    const quickbooksBillId = item.Id;
    if (!quickbooksBillId) return;
    const baseQBURL = this.isProduction ? 'https://qbo.intuit.com' : 'https://sandbox.qbo.intuit.com';
    const url = `${baseQBURL}/app/bill?txnId=${quickbooksBillId}`;
    this.openInNewTab(url);
  }

}
