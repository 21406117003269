<div *ngIf="isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>

<ng-container *ngIf="data">
  <div style="display: flex; justify-content: space-between; margin-top: 15px; margin-bottom: 15px;">
    <div class="text-label1">Cost breakdown</div>
    <button nz-button (click)="onBtnEdit()" [disabled]="isClosed">Edit <i nz-icon nzType="edit"></i></button>
  </div>
  <div display-fin-job-cost [finType]="finType" [data]="finJobCost"></div>

  <div style="display: flex; justify-content: space-between; margin-top: 25px; margin-bottom: 15px;">
    <div class="text-label1">Transaction history</div>
  </div>
  <div fin-job-tx-history [txArr]="data.txArr"></div>
</ng-container>