<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Create Statement For Carrier Fixed Rate</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <form nz-form *ngFor="let groupKey of ['payable']" [formGroupName]="groupKey">
      <div nz-row [nzGutter]="16">
        <div nz-col [nzSm]="8">
          <div *ngIf="'accountId' as key">
            <div class="form-label-v2">{{ getLabel(fullKey(groupKey, key)) }}<span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzBackdrop="true" nzShowSearch
                  [formControlName]="key" [nzLoading]="isFetchingFinAccounts"
                  [nzPlaceHolder]="getPlaceHolder(fullKey(groupKey, key))"
                  nzDropdownClassName="ap-list-filter-{{key}}"
                  (ngModelChange)="onChangeAccountId($event)" style="width: 250px">
                  <nz-option *ngFor="let item of listFinAccounts" 
                    [nzValue]="item.id" [nzLabel]="item.name">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div *ngIf="fixedRateInfo.type && fixedRateInfo.rate" nz-col [nzSm]="5">
          <div class="form-label-v2">Payment Type:</div>
          <div class="bold">{{ formatMoney(fixedRateInfo.rate) }} {{ getPaymentFixedRateTypeName(fixedRateInfo.type) }}</div>
        </div>
        <div *ngIf="fixedRateInfo.type" nz-col [nzSm]="5">
          <div class="form-label-v2">Number of Day:</div>
          <div class="bold">{{ highlightDays.length }}</div>
        </div>
        <div *ngIf="fixedRateInfo.type" nz-col [nzSm]="5">
          <div class="form-label-v2">Total amount:</div>
          <div class="bold">{{ getTotalAmount }}</div>
        </div>
      </div>
      <div nz-row [nzGutter]="16">
        <div nz-col [nzSm]="8">
          <div *ngIf="'paymentServiceName' as key">
            <div class="form-label-v2">{{ getLabel(fullKey(groupKey, key)) }}<span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span></div>
            <nz-form-item>
              <nz-form-control>
                <nz-select [formControlName]="key" style="width: 250px;">
                  <nz-option *ngFor="let option of listOfPaymentService" [nzLabel]="option" [nzValue]="option">
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-col [nzSm]="16">
          <div *ngIf="'invoiceFile' as key">
            <input #inputFile type="file" hidden accept=".png,.jpg,.jpeg,.pdf" (change)="onFileSelected(fullKey(groupKey, key), inputFile.files)">
              <div *ngIf="!hasAttachedFile(fullKey(groupKey, key))">
                <div class="form-label-v2">{{getLabel(fullKey(groupKey, key))}}<span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span></div>
                <button nz-button nzType="default" (click)="inputFile.click()">
                  <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
                </button>
              </div>
              <div *ngIf="hasAttachedFile(fullKey(groupKey, key))">
                <div class="form-label-v2">{{getLabel(fullKey(groupKey, key))}}<span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span></div>
                <div class="flex-space-between" style="align-items: center;">
                  <div class="file-selected">
                    <i nz-icon nzType="paper-clip" nzTheme="outline"></i>{{ labelSelectFile }}
                  </div>
                  <button nz-button nzType="text" (click)="delFile(fullKey(groupKey, key), inputFile)">
                    <i nz-icon nzType="delete"></i>
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
      
      <div *ngIf="fixedRateInfo.type" class="flex">
        <div style="width: 400px;">
          <div class="form-label-v2">Choose working days <span class="label-mark-required"></span></div>
          <nz-calendar [nzFullscreen]="false" [nzDateFullCell]="dateCellTpl" (nzSelectChange)="dateSelectChange($event)">
          </nz-calendar>
          <ng-template #dateCellTpl let-date>
            <span *ngIf="!isHighlight(date)">{{ date | date:'d'}}</span>
            <span *ngIf="isHighlight(date)" 
              [ngClass]="{highlight: isHighlight(date)}" 
              style="z-index: 1000;"
              >{{ date | date:'d'}}
            </span>
          </ng-template>
        </div>
        <div class="left20" style="width: 100%;">
          <div>Summary</div>
          <nz-table #nzTable [nzData]="highlightDays" [nzShowPagination]="false" [nzFrontPagination]="false"
            nzBordered="true" [nzSize]="'small'">
            <thead>
              <tr>
                <th nzWidth="200px">Date</th>
                <th>Routes</th>
                <th>Additional services</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of nzTable.data">
                <td> {{ item }}</td>
                <td>
                  <div *ngIf="dataRouteForHighlightDay[item]?.isLoading">
                    <nz-spin nzSimple></nz-spin> 
                  </div>
                  <div *ngIf="!dataRouteForHighlightDay[item]?.isLoading">
                    <ng-container *ngIf="dataRouteForHighlightDay[item]?.routes?.length">
                      <div *ngFor="let it of dataRouteForHighlightDay[item].routes" class="flex bottom5">
                        <div>{{ it.code }}</div>
                        <div class="left20"><a [href]="it.jobUrl" target="_blank">{{ it.jobCode }}</a></div>
                        <div class="left20 fin-status {{it.intermediateStatus}}">{{batchStatus(it)}}</div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!dataRouteForHighlightDay[item]?.routes?.length">
                      <div>No data</div>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <div *ngFor="let cost of getAdditionalServceByDay(item); let i = index" class="flex-space-between" style="align-items: center;">
                    <div>{{ i + 1 }}. {{ cost.name }} - {{ formatMoney(cost.rate) }}</div>
                    <div>
                      <button nz-button nzDanger nzType="text" (click)="onBtnRemoveAdditionalService(cost)" >
                        <i nz-icon nzType="close-square" nzTheme="outline" style="font-size: 20px;"></i>
                      </button>
                    </div>
                  </div>
                  <div class="flex" style="justify-content: flex-end;">
                    <button nz-button (click)="onBtnAdditionalServices(item)" nzSize="small">
                      <i nz-icon nzType="plus"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </form>
    <div>
      <div>
        Select an order for receivable
        <span class="label-mark-required"></span>
      </div>
      <div>
        <nz-table #nzTable [nzData]="dataOrderGroups" [nzShowPagination]="false" [nzFrontPagination]="false"
          nzBordered="true" [nzSize]="'small'" [nzLoading]="isLoadingRefreshListOrder">
          <thead>
            <tr>
              <th nzWidth="200px">Customer</th>
              <th>
                <div class="flex" style="align-items: center;">
                  <div>Orders</div>
                  <div class="left10">
                    <button nz-button nzType="text" nzSize="small" (click)="onBtnSyncListOrder()" nz-tooltip nzTooltipTitle="Refresh">
                      <span nz-icon nzType="sync" nzTheme="outline"></span>
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of nzTable.data">
              <td> {{ item.name }}</td>
              <td>
                <div *ngFor="let child of item.childrens" class="flex" style="margin-bottom: 3px;">
                  <div>
                    <a [href]="getDetailOrderLink(child)" target="_blank">{{ child.code }}</a>
                  </div>
                  <div class="left10 flex1">
                    (
                      <span>ShipmentType: {{ child.shipmentType}}</span>
                      - 
                      <span>GrandTotal: {{ getGrandTotal(child) }}</span>
                      -
                      <span *ngIf="child.invoiceFileId">Invoice has been created</span>
                      <span *ngIf="!child.invoiceFileId" class="warning-no-invoice">Not yet generate invoice</span>
                    )
                  </div>
                  <div class="left10" *ngIf="!isSelectOrder(child)">
                    <button 
                      nz-button 
                      [disabled]="!child.invoiceFileId" 
                      nzSize="small" 
                      nz-tooltip [nzTooltipTitle]="!child.invoiceFileId ? 'Please generate invoice first' : ''"
                      (click)="onBtnSelectOrder(child)">Select</button>
                  </div>
                  <div class="left10" *ngIf="isSelectOrder(child)">
                    <div class="selected-order">
                      <span nz-icon nzType="check-circle" nzTheme="outline" style="margin-right: 2px;"></span>Selected
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Create" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>