

<!-- <div class="row">
  <div class="label"></div>
  <div class="col-rate">Rate</div>
  <div class="col-qty">Quantity</div>
  <div class="col-total">Total</div>
</div>

<div class="row">
  <div class="label">Transit Cost</div>
  <div class="col-rate">{{formatMoney(data.transitCost?.rate)}}</div>
  <div class="col-qty">{{data.transitCost?.qty || 0}}</div>
  <div class="col-total">{{formatMoney(data.transitCost?.total)}}</div>
</div>

<div class="row">
  <div class="label">Fuel Cost</div>
  <div class="col-rate">{{formatMoney(data.fuelCost?.rate)}}</div>
  <div class="col-qty">{{data.fuelCost?.qty || 0}}</div>
  <div class="col-total">{{formatMoney(data.fuelCost?.total)}}</div>
</div>

<ng-container *ngIf="serviceOptions.length > 0">
  <div class="row">
    <div class="label">Service Options</div>
  </div>
  
  <div class="row" *ngFor="let item of serviceOptions">
    <li class="label">{{getServiceOptionName(item)}}</li>
    <div class="col-rate">{{formatMoney(item.rate)}}</div>
    <div class="col-qty">{{item.qty}}</div>
    <div class="col-total">{{getServiceOptionMoneyValue(item)}}</div>
  </div>
</ng-container> -->

<nz-table #nzTable [nzData]="displayItems"
  [nzShowPagination]="false" [nzSize]="'default'"
  nzBordered="true" nzSize="small">
  <thead>
    <tr>
      <th>Fee name</th>
      <th>Amount</th>
      <th>Quantity</th>
      <th>Subtotal</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of nzTable.data; let i = index">
      <tr>
        <td>{{item.name}}</td>
        <td>{{item.rate}}</td>
        <td>{{item.qty}}</td>
        <td>{{item.total}}</td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>