import { Component } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ResponseAdminFinBatchListItem } from '@wearewarp/types/rest-api/admin/fin';
import { FinArTabFilter, FinArCountTab } from '../interface';
import { FinUtil } from '../../util';
import { DialogService } from '@dialogs/dialog.service';
import { ConfirmPod } from '@app/admin/shipments/confirm-pod';
import { CreateFinStatement } from '../../components/fin-statement/create';
import { DetailFinStatement } from '../../components/fin-statement/detail';
import { DateUtil } from '@services/date-utils';
// import { MasterData } from '@services/master.data';
// import dayjs from 'dayjs';
// import { Log } from '@services/log';
// import { getInjector } from '@services/injector';
// import { NzModalService } from 'ng-zorro-antd/modal';

import { ListOfStatementBillClientIds } from '../../const';

function SortStringFn(a: string, b: string){
  return a.localeCompare(b);
}
function SortArrStringFn(a: string[], b: string[]){
  return (a?.[0] || '').localeCompare(b?.[0] || '');
}
function SortNumberFn(a: number, b: number){
  return a - b;
}
@Component({
  selector: 'fin-receivale-batch-list',
  templateUrl: './index.html',
  styleUrls: ['../../../list.scss', './index.scss', '../../status.scss']
})
export class FinReceivableBatchList extends BaseList<ResponseAdminFinBatchListItem> {
  public sortDirection = ['ascend', 'descend', null]
  private _selectedTabIndex = 0;
  public countSum: FinArCountTab = {}
  get isReadyToProcess(): boolean { return this.currentTabFilter == FinArTabFilter.readyToProcess }
  get isAlreadyProcessed(): boolean { return this.currentTabFilter == FinArTabFilter.alreadyProcessed }
  constructor() {
    super();
  }

  get currentTabFilter(): FinArTabFilter {
    switch (this._selectedTabIndex) {
      case 0: return FinArTabFilter.needPodUpload;
      case 1: return FinArTabFilter.needPodConfirm;
      case 2: return FinArTabFilter.readyToProcess;
      case 3: return FinArTabFilter.alreadyProcessed;
      case 4: return FinArTabFilter.notFinished;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
  }

  protected onGetDataSucceeded(resp: any): void {
    if (resp?.data?.total) {
      this.updateCountSum(this.currentTabFilter, resp.data.total);
      this.syncListDenimStatus();
      this.buildDisplayInfos(resp?.data?.list_data);
    }
  }

  showBatchCodeAR(item) {
    return `AR-${item.code}`;
  }

  getRouterLinkDetail(item) {
    return `${Const.routeAdminFinAR}/${item.id}`;
  }

  public getAmountValue(item) {
    return '$' + InputHelper._formatMoney(item.amt, 0);
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  protected getApiUrl(): string {
    return this.getApiByTab(this.currentTabFilter);
  }

  private updateCountSum(tab: FinArTabFilter, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private countAll() {
    [
      FinArTabFilter.notFinished,
      FinArTabFilter.needPodUpload,
      FinArTabFilter.needPodConfirm,
      FinArTabFilter.readyToProcess,
      FinArTabFilter.alreadyProcessed,
    ].map(it => this.countForTab(it));
  }

  private countForTab(tab: FinArTabFilter) {
    const params = this.prepareParamGetList();
    const url = Utils.appendQueryStringIntoUrl(`${this.getApiByTab(tab)}?countOnly=true`, params);
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.total != null) {
          this.updateCountSum(tab, resp.data.total);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  private getApiByTab(tab: FinArTabFilter) {
    switch (tab) {
      case FinArTabFilter.notFinished: return Const.APIURI_FINANCES_BATCH('list_not_finished');
      case FinArTabFilter.needPodUpload: return Const.APIURI_FINANCES_BATCH('list_need_pod_upload');
      case FinArTabFilter.needPodConfirm: return Const.APIURI_FINANCES_BATCH('list_need_pod_confirm');
      case FinArTabFilter.readyToProcess: return Const.APIURI_FINANCES_BATCH('list_ready_to_process');
      case FinArTabFilter.alreadyProcessed: return Const.APIURI_FINANCES_BATCH('list_already_processed');
    }
  }

  batchStatus(item: ResponseAdminFinBatchListItem) {
    return FinUtil.showStatusFinIntermediate(item.intermediateStatus);
  }

  createStatement(item: ResponseAdminFinBatchListItem) {
    DialogService.comingSoon();
  }

  onOpenConfirmPod(item: ResponseAdminFinBatchListItem) {
    // this.itemLastConfirmingPod = null;
    // const isInvoiceWithoutPOD = this.isInvoiceWithoutPOD(item);
    // let afterClose = new EventEmitter();
    // afterClose.subscribe(() => {
    //   // this.itemLastConfirmingPod = item;
    // })
    DialogService.openDialog(ConfirmPod, {
      nzComponentParams: {
        data: { orderId: item.relatedBiz.id, status: item?.status },
        onDone: invoice => {
          this.onBtnRefresh();
          // item.invoice = invoice;
          // item.invoiceFileId = invoice._id;
        }
      },
      nzClosable: false,
      nzClassName: 'modal-no-padding modal-fullscreen',
      nzCentered: true,
      // nzAfterClose: afterClose,
    });
  }

  onBtnCreateStatement(item: ResponseAdminFinBatchListItem) {
    if (!this.isReadyToProcess && !this.isAlreadyProcessed) return;
    DialogService.openFormDialog1(CreateFinStatement, {
      nzComponentParams: {
        closeOnSuccess: true,
        comeFromScreen: 'receivableFinance',
        model: {
          batch: { id : item.id, code: item.code },
          finAccount: { id: item.account.id, name: item.account.name },
          relatedBiz: { id: item.relatedBiz.id, code: item.relatedBiz.code },
          invoiceFiles: item.invoiceFiles ?? [],
          cost: item.amt
        },
        onRefresh: () => this.onBtnRefresh()
      },
      nzClassName: "modal-lg",
    });
  }

  onBtnViewStatement(item: ResponseAdminFinBatchListItem) {
    if ((!this.isReadyToProcess && !this.isAlreadyProcessed) || !item.statement?.id) return;
    DialogService.openDialog1(DetailFinStatement, {
      nzComponentParams: {
        id: item.statement.id,
        onRefresh: () => { 
          this.onBtnRefresh();
          this.countAll();
        }
      },
      nzClassName: "modal-xl",
    });
  }

  getLabelViewStatement(item: ResponseAdminFinBatchListItem) {
    if (item.statement?.id) {
      return `ST-${item.statement.code}`;
    }
    return 'View Statement'
  }

  private buildDisplayInfos(listData: ResponseAdminFinBatchListItem[]) {
    if (!listData?.length) return;
    for (let item of listData) {
      item['displayInfo'] = {
        pickWindow: this.getTimePeriods(item, 'pickInfo'),
        dropWindow: this.getTimePeriods(item, 'dropInfo'),
        pickRefNums: this.getOrderRefNums(item, 'pickInfo'),
        dropRefNums: this.getOrderRefNums(item, 'dropInfo'),
      }
    }
  }

  getOrderRefNums(item: ResponseAdminFinBatchListItem, type: 'pickInfo' | 'dropInfo') {
    let arr;
    if (type == 'pickInfo') {
      arr = item.relatedBiz?.metadata?.pickInfo?.refNums;
    } else if (type == 'dropInfo') {
      arr = item.relatedBiz?.metadata?.dropInfo?.refNums;
    }
    if(arr && arr.length) {
      return arr;
    }
    return null;
  }

  getTimePeriods(item, type: 'pickInfo' | 'dropInfo') {
    if (type == 'pickInfo') {
      return DateUtil.displayTimeWindow(item.relatedBiz?.metadata?.pickInfo?.window, {
        timezone: item.relatedBiz?.metadata?.pickInfo?.timeZoneStandard,
        format: "MMM D, YYYY HH:mm",
        formatDateOnly: "MMM D, YYYY"
      })
    } else if (type == 'dropInfo') {
      return DateUtil.displayTimeWindow(item.relatedBiz?.metadata?.dropInfo?.window, {
        timezone: item.relatedBiz?.metadata?.dropInfo?.timeZoneStandard,
        format: "MMM D, YYYY HH:mm",
        formatDateOnly: "MMM D, YYYY"
      })
    }
  }

  formatMoney(value: number) {
    return '$' + InputHelper._formatMoney(value, 0);
  }

  getCarriers(item: ResponseAdminFinBatchListItem) {
    return item.relatedBiz?.metadata?.carriers;
  }

  public sortCarriers(a: ResponseAdminFinBatchListItem, b: ResponseAdminFinBatchListItem){
    return SortArrStringFn(a.relatedBiz?.metadata?.carriers?.map(it => it?.basicInfo?.name) || [], b.relatedBiz?.metadata?.carriers?.map(it => it?.basicInfo?.name) || []);
  } 

  public sortRate(a: ResponseAdminFinBatchListItem, b: ResponseAdminFinBatchListItem){
    return SortNumberFn(a.relatedBiz?.metadata?.customerRate, b.relatedBiz?.metadata?.customerRate);
  }
  
  public sortPayer(a: ResponseAdminFinBatchListItem, b: ResponseAdminFinBatchListItem){
    return SortStringFn(a.account.name, b.account.name);
  }

  public getinvoiceSentInfo(item: ResponseAdminFinBatchListItem) {
    return item?.relatedBiz?.metadata?.invoiceSentInfo || [];
  }

  public canToggeInvoiceSent(item, index: number) {
    //tam thoi chi cho view
    return false;
    // if(item.isLoading) return false;
    // // invoiceSentInfo là mảng được sắp xếp theo thứ tự invoice -> additionalInvoice 
    // const invoiceSentInfo = this.getinvoiceSentInfo(item);
    // if(index != invoiceSentInfo.length - 1) return false;
    // const element = invoiceSentInfo[index];
    // if(element?.when){
    //   //now is checked, want to uncheck
    //   const markWhen = new Date(element.when).getTime();
    //   let isValidTimeAllow = Date.now() - markWhen < MasterData.getTimeAllowUncheckInvoiceSent();
    //   if (isValidTimeAllow) return true;
    //   const timestampEndOfMonth = this.getTimestampEndOfMonth(new Date(invoiceSentInfo[0].when)).valueOf();
    //   return Date.now() <= timestampEndOfMonth;
    // }else{
    //   //now is unchecked, want to check
    //   return true;
    // }
  }

  // lấy thời điểm cuối cùng của tháng invoice sent theo timezone Mỹ để check disable invoice sent
  // 'Pacific/Pago_Pago';       // GMT-11:00
  // getTimestampEndOfMonth(invoiceSentWhen: Date) {
  //   const when = dayjs(invoiceSentWhen).utc();
  //   const pacificDate = when.tz('Pacific/Pago_Pago');
  //   const endOfMonth = pacificDate.endOf('month');
  //   return endOfMonth.valueOf()
  // }

  // onBtnInvoiceSent(item, index, value) {
  //   let invoiceSentInfo = this.getinvoiceSentInfo(item);
  //   let invoiceSent = invoiceSentInfo[index];
  //   let isInvoiceSent = !!invoiceSent?.when;
  //   if (value == isInvoiceSent) {
  //     return;
  //   }
  //   const modalService = getInjector().get(NzModalService);
  //   let title = value ? 'Are you sure you want to mark it as already sent?' : 'Are you sure you want to mark it as not sent yet?';
  //   modalService.confirm({
  //     nzTitle: title,
  //     nzClosable: false,
  //     nzMaskClosable: false,
  //     nzCentered: true,
  //     nzOkText: 'Yes',
  //     nzOnOk: () => {
  //       if(invoiceSent.isAddtionalCost) {
  //         this.saveAdditionalInvoiceSent(item, index, value);
  //       }else{
  //         this.saveInvoiceSent(item, index, value);
  //       }
        
  //     },
  //     nzCancelText: 'No',
  //     nzOnCancel: () => {
  //       invoiceSent.isInvoiceSent = !value
  //     }
  //   });
  // }

  // private saveInvoiceSent(item, index, value: boolean) {
  //   let invoiceSentInfo = this.getinvoiceSentInfo(item);
  //   let invoiceSent = invoiceSentInfo[index];
  //   if(!invoiceSent) return;
  //   let url = Const.APIV2(`${Const.APIURI_ORDERS}/${invoiceSent.orderId}/invoice_sent`);
  //   item.isLoading = true;
  //   this.api.PUT(url, { value }).subscribe(
  //     resp => {
  //       Log.d('onBtnInvoiceSent resp: ', resp);
  //       item.isLoading = false;
  //       item.relatedBiz.metadata.invoiceSentInfo[index] = {
  //         orderId: invoiceSent.orderId,
  //         ...resp?.data?.invoiceSent,
  //         isInvoiceSent: value,
  //         isAddtionalCost: false
  //       };
  //     }, err => {
  //       this.showErr(err);
  //       invoiceSent.isInvoiceSent = !value;
  //       item.isLoading = false;
  //     }
  //   );
  // }

  // private saveAdditionalInvoiceSent(item, index, value) {
  //   let invoiceSentInfo = this.getinvoiceSentInfo(item);
  //   let additionalCost = invoiceSentInfo[index];
  //   if(!additionalCost) return;
  //   let url = Const.APIV2(`${Const.APIURI_ORDERS}/${additionalCost.orderId}/additional_invoice_sent`);
  //   item.isLoading = true;
  //   this.api.PUT(url, { id: additionalCost.id, value }).subscribe(
  //     resp => {
  //       item.isLoading = false;
  //       const afterUpate = resp?.data?.additionalCosts?.find(it => it.id == additionalCost.id)?.invoiceSent;
  //       item.relatedBiz.metadata.invoiceSentInfo[index] = {
  //         ...afterUpate,
  //         id: additionalCost.id,
  //         orderId: additionalCost.orderId,
  //         isInvoiceSent: value,
  //         isAddtionalCost: true
  //       }
  //     }, err => {
  //       this.showErr(err);
  //       additionalCost.isInvoiceSent = !value;
  //       item.isLoading = false;
  //     }
  //   );
  // }

  public getCarrierPaidInfo(item: ResponseAdminFinBatchListItem) {
    return item?.relatedBiz?.metadata?.carierPaidInfo || [];
  }

  canToggeCarrierPaid(item, index: number){
    return false;
  }

  getTooltipTitleCarrierPaid(carrierPaid){
    return `Carrier ${carrierPaid?.isAdditionalCost ? 'Additonal Cost' : 'Cost'} of route ${carrierPaid?.jobCode}`
  }

  getDenimJobLink(item: ResponseAdminFinBatchListItem) {
    return item?.statement?.denimJobLink ?? null;
  }

  isClientStatementBill(item) {
    return false;
    // return ListOfStatementBillClientIds.includes(item.account?.id);
  }

  isLoadingDenimStatus = false;
  private syncListDenimStatus() {
    let arrStatementIds = this.listData.map(it => it.statement?.id);
    arrStatementIds = Utils.uniqElementsArray(arrStatementIds.filter(x=>x))
    if (!arrStatementIds.length) return;
    this.isLoadingDenimStatus = true;
    const url = Const.APIURI_FINANCES_BATCH('batch_denim_status');
    this.api.POST(url, { statementIds: arrStatementIds }).subscribe(
      resp => {
        let statementDataDic = {};
        for (let item of resp?.data?.list_data ?? []) {
          if (!item?.statementId) continue;
          statementDataDic[item.statementId] = item;
        }
        for (let item of this.listData) {
          const statementId = item.statement?.id;
          if (!statementId) continue;
          const statementData = statementDataDic[statementId];
          if (!statementData) continue;
          item['denimJobStatus'] = statementData.denimJobStatus; 
          item['denimJobCreatedWhen'] = statementData.denimJobCreatedWhen ? DateUtil.dateToString(statementData.denimJobCreatedWhen, Const.FORMAT_GUI_DATE) : ''; 
        }
        this.isLoadingDenimStatus = false;
      }, err => {
        this.isLoadingDenimStatus = false;
      }
    );
  }

  getLabelDenimStatus(status) {
    switch (status) {
      case 'draft': return 'Draft';
      case 'pending': return 'Pending Approval';
      case 'approved': return 'Approved';
      case 'rejected': return 'Rejected';
      case 'all_active': return 'All Active';
      case 'all_inactive': return 'All Inactive';
      case 'completed': return 'Completed';
      default: return 'N/A';
    }
  }

  public getColorTagStatus(status) {
    switch (status) {
      case 'draft': return 'default';
      case 'pending': return 'gold';
      case 'approved': return 'blue';
      case 'rejected': return 'red';
      case 'all_active': return 'green';
      case 'all_inactive': return 'red';
      case 'completed': return 'green';
      default: return 'default';
    }
  }

}