import { Component, Input } from "@angular/core";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "popup-location",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class PopupLocation {
  @Input() infoLocation: any;
  @Input() isSmallMap: boolean = true;
  displayInfo: any = {};

  constructor() {}

  ngOnInit() {
    this.buildDisplayInfo();
  }

  buildDisplayInfo() {
    if(this.infoLocation) {
      const { location, ts, source } = this.infoLocation;
      const { latitude, longitude } = location || {};
      let speed = location?.speed;
      if (speed == null || speed < 0) speed = 0;
      let mph = speed && source && source !== 'driver-app' ? (speed || 0) : (speed || 0) * 3600 / 1609.34;
      if (mph < 0) mph = 0;
      this.displayInfo = {
        time: DateUtil.displayLocalTime(ts, { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }),
        gps: latitude?.toFixed(3) + "," + longitude?.toFixed(3),
        speed: mph.toFixed(0) + " mph"
      }
    }
  }
}