<nz-table #nzTable [nzData]="txArr"
  [nzShowPagination]="false" [nzSize]="'default'"
  nzBordered="true" nzSize="small">
  <thead>
    <tr>
      <th>Transaction ID</th>
      <!-- <th>Status</th> -->
      <th>Added Amount</th>
      <th>Description</th>
      <th>Created when</th>
      <th>Created by</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of nzTable.data; let i = index">
      <tr>
        <td>
          <a (click)="openTxDetail(item)">{{item.id}}</a>
        </td>
        <!-- <td>{{displayTxStatus(item)}}</td> -->
        <td>{{getMoneyValue(item.amt)}}</td>
        <td>
          <div>{{getTxDesc(item)}}</div>
        </td>
        <td>{{displayDateTime(item)}}</td>
        <td>
          <a class="link" target="_blank" [routerLink]="routerLinkUser(item.created.byUser)">{{displayUser(item)}}</a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</nz-table>