import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FinPayableBatchList } from './batch-list';
import { ModuleFinPayableBatchListFilter } from './batch-list/filter/module';
import { FinPayableBatchDetail } from './batch-detail';
import { ModuleFinBatch } from '../components/batch/module';
import { ModuleCarrierCostIssue } from '@app/admin/dispatch/components/carrier-and-driver/cost-issue/module';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { FileToolModule } from '@app/admin/components/file/module';
import { CreateStatementModeDlg } from './select-create-statement-dlg';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzTabsModule,
    NzIconModule,
    NzModalModule,
    NzButtonModule,
    ModuleCarrierCostIssue,
    ModuleFinPayableBatchListFilter,
    ModuleFinBatch,
    NzTagModule,
    FileToolModule
  ],
  declarations: [FinPayableBatchList, FinPayableBatchDetail, CreateStatementModeDlg],
  exports: [FinPayableBatchList, FinPayableBatchDetail]
})
export class AccountsPayableModule {
}