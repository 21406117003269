import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Utils } from "@services/utils";
import { ResponseDenimFetchingRelationship } from "../../interface";

@Component({
  selector: 'search-denim-company',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class SearchDenimCompany extends BaseFormDialog1 {

    public isLoading = false;
    public listOfData: ResponseDenimFetchingRelationship[] = [];
    public limit = 3;

    @Input() companyType: string;
    @Input() accountInfo;
    @Input() onOk: (resp) => void;
    @Input() selectedCompanies: ResponseDenimFetchingRelationship[] = [];
    @Input() onClose: (reload: boolean) => void;

    protected formGroupDeclaration: FormGroupDeclaration = {
      companyName: { label: "Company name or MC number", required: true }
    };

    get isCompanyDebtor() {
      return this.companyType == 'debtor';
    }

    ngOnInit(): void {
      super.ngOnInit();
      this.listOfData = this.selectedCompanies;
    }

    getLabel(key) {
      switch (key) {
        case 'companyName':
          return this.isCompanyDebtor ? "Customer name or MC number" : "Contractor name or MC number";
        default:
          return super.getLabel(key);
      }
    }

    onBtnSearchCompany() {
      const companyName = this.getItemValue('companyName');
      if (!companyName) {
        this.showWarning('', `Please enter ${this.isCompanyDebtor ? "customer" : "contractor"} name or MC number`);
        return;
      }
      const params = {
        query: companyName,
        companyType: this.companyType,
      }
      this.isLoading = true;
      let url = Const.APIURI_FINANCES_STATEMENT('seach-denim-company-relationship');
      url = Utils.appendQueryStringIntoUrl(url, params);
      this.api.GET(url).subscribe(
        resp => {
          const _data = (resp?.data?.list_data ?? []).filter(item => !this.isSelected(item));
          this.listOfData = [...this.selectedCompanies, ..._data];
          this.processData();
          this.isLoading = false;
        }, err => {
          this.showErr(err)
          this.listOfData = [];
          this.isLoading = false;
        }
      );
    }

    private processData() {
      for (let item of this.listOfData) {
        if (!item.company) return;
        let objAddr = {
          street: (item.company as any).address_line_1,
          street2: (item.company as any).address_line_2,
          city: item.company.city,
          state: item.company.state,
          zipcode: (item.company as any).zip_code,
        }
        item.company['fullAddress'] = this.getAddressText(objAddr);
      }
    }

    onBtnSelectItem(item: ResponseDenimFetchingRelationship) {
      this.onOk(item);
      this.closeDialog();
    }

    onBtnClose() {
      this.onClose?.(true);
      this.closeDialog();
    }

    onBtnConfirm(item: ResponseDenimFetchingRelationship) {
      this.isLoading = true;
      let url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${this.accountInfo?.finAccount?.id}/denim`);
      url = Utils.appendQueryStringIntoUrl(url, {type: 'add'});
      this.api.PUT(url, item).subscribe(
        resp => {
          this.selectedCompanies.push(item);
          this.showSuccess('Success', 'Relationship confirm successfully');
          this.isLoading = false;
        }, err => {
          this.showErr(err)
          this.isLoading = false;
        }
      );
    }

    onBtnRemove(item: ResponseDenimFetchingRelationship) {
      this.isLoading = true;
      let url = Const.APIV2(`${Const.APIURI_FINANCES}/account/${this.accountInfo?.finAccount?.id}/denim`);
      url = Utils.appendQueryStringIntoUrl(url, {type: 'remove'});
      this.api.PUT(url, item).subscribe(
        resp => {
          this.selectedCompanies = this.selectedCompanies.filter(selectedItem => selectedItem.company.id !== item.company.id);
          this.showSuccess('Success', 'Relationship removed successfully');
          this.isLoading = false;
        }, err => {
          this.showErr(err)
          this.isLoading = false;
        }
      );
    }

    isSelected(item: ResponseDenimFetchingRelationship) {
      return this.selectedCompanies.some(selectedItem => selectedItem.company.id === item.company.id);
    }
  }
