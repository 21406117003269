<div style="display: flex; justify-content: space-between;">
  <div class="text-label1" style="display: flex;">
    {{showFinJobCode(finJob)}}
    <div class="left10 fin-status {{finJob.intermediateStatus}}">{{showFinJobStatus(finJob)}}</div>
    <div *ngIf="isAP" class="left10">
      <button *ngIf="shouldShowCreateQBBill" nz-button nzType="default" nzSize="small" (click)="onBtnCreateQBBill()">
        <span style="font-size: 13px;">Create Quickbooks Bill</span>
      </button>
      <a *ngIf="quickbooksBillLink" [href]="quickbooksBillLink" style="font-size: 13px;" target="_blank">
        View Quickbooks Bill
      </a>
    </div>
  </div>
  <div class="text-fin-job-amt">
    <div class="text-fin-job-amt-desc right20">{{getAmtDesc(finJob)}}</div>
    {{showFinJobAmt(finJob)}}
  </div>
</div>
<div style="display: flex; justify-content: space-between;">
  <div class="top10">
    <div *ngIf="parentFinJob" class="biz-entity">
      <div class="label">{{showRelatedBizType()}}</div>
      <a target="_blank" [routerLink]="routerLinkRelatedBiz()">{{showRelatedBizCode()}}</a>
    </div>
    <div *ngIf="isAR" class="top10">
      <ng-container *ngIf="!finJob?.invoiceFile?.id">
        Invoice: N/A
      </ng-container>
      <ng-container *ngIf="finJob?.invoiceFile?.id">
        <file-tool [file]="finJob?.invoiceFile" [fileName]="finJob.invoiceFile.name ?? finJob.invoiceFile.id" [uploadTxt]="''"></file-tool>
      </ng-container>
    </div>
    <div *ngIf="isAP" class="top10 center-vertical gap10">
      <file-tool [file]="finJob?.invoiceFile" [fileName]="getCarrierInvoiceName()" [uploadTxt]="'Upload Invoice'" (onUpload)="uploadCarrierInvoice($event)"></file-tool>
      <a *ngIf="finJob?.invoiceFile" nz-button nzType="link" (click)="onDeleteCarrierInvoice()"><span nz-icon [nzType]="'delete'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></span></a>
    </div>
  </div>
  <div class="text-breakdown clickable top10" (click)="toggleFinJobBreakdown()">View breakdown <i nz-icon nzType="down" nzTheme="outline" [nzRotate]="isExpand ? 0 : -90"></i></div>
</div>
<nz-collapse nzGhost [nzExpandIconPosition]="'right'">
  <nz-collapse-panel class="finjob-breakdown" [nzHeader]="" [(nzActive)]="isExpand" [nzShowArrow]="false">
    <div class="hline"></div>
    <div fin-job-breakdown [finType]="finType" [finJobId]="finJob.id" [parentFinJob]="parentFinJob"></div>
  </nz-collapse-panel>
</nz-collapse>