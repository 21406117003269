<div class="bottom20" style="min-height: 200px;">
  <div *ngIf="isLoadingService" style="padding-top: 20px;">
    <nz-spin nzTip="Loading..."></nz-spin> 
  </div>
  <div *ngIf="!isLoadingService">
    <div *ngIf="errorAuthMsg">
      <div class="error bottom10"> {{ errorAuthMsg }}</div>
      <button nz-button class="btn-connect-to-quickbooks" (click)="onBtnConnectToQuickbooks()">Connect to Quickbooks</button>
    </div>
    <div *ngIf="!errorAuthMsg">
      <!-- begin view main-->
      <div *ngIf="accountInfo" class="bottom20">
        <div class="medium bottom5">WARP Carrier Information</div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">Name: </div>
          <div>{{ accountInfo.basicInfo?.name || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">MC: </div>
          <div>{{ accountInfo.basicInfo?.mc || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">DOT: </div>
          <div>{{ accountInfo.basicInfo?.dot || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">Address: </div>
          <div>{{ getAddressText(accountInfo.basicInfo?.address) || 'N/A' }}</div>
        </div>
        <div class="carrier-info-item">
          <div class="carrier-info-label">Website: </div>
          <div>{{ accountInfo.basicInfo?.website || 'N/A' }}</div>
        </div>
      </div>
      <form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput" nzLayout="vertical">
        <div *ngIf="'companyName' as key">
          <nz-form-item>
            <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
            <div class="flex">
              <nz-form-control>
                <input nz-input [placeholder]="getPlaceHolder(key)"
                  [formControlName]="key"/>
              </nz-form-control>
              <button
                nz-button
                nzType="primary"
                (click)="onBtnSearchCompany()"
                [nzLoading]="isLoadingVendor"
                style="width: 100px; margin-left: 20px;"
                >Search</button>
            </div>
          </nz-form-item>
        </div>
        <div class="warning">Note: If you do not found result, please create vendor quickbooks at <a (click)="onBtnCreateVendor()">here</a></div>
      </form>
      <div class="medium top10 bottom5">{{ listOfData.length }} {{ listOfData.length > 1 ? 'results' : 'result' }}</div>
      <div>
        <nz-table
          #nzTable nzBordered="true"
          [nzLoading]="isLoadingVendor"
          [nzLoadingIndicator]="tplLoading"
          [nzData]="listOfData" nzSize="small"
          [nzHideOnSinglePage]="true"
          [nzPageSize]="limit"
          [nzNoResult]="tplNoData">
          <ng-template #tplLoading>
            <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
          </ng-template>
          <ng-template #tplNoData>
            <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
          </ng-template>
          <thead>
            <tr>
              <th>Company Info</th>
              <th nzWidth="100px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of nzTable.data; let i = index">
              <td>
                <div>Company Name: 
                  <b>
                    <a [href]="item.displayInfo?.qbLink" target="_blank" nz-tooltip="Go to Quickbooks">
                      {{ item.displayInfo?.companyName }}
                    </a>
                  </b>
                </div>
                <div>Bill Address: {{ item.displayInfo?.billAddr }} </div>
                <div>Email: {{ item.displayInfo?.email }}</div>
                <div>Phone Number: {{ item.displayInfo?.phone }}</div>
                <div *ngIf="item.displayInfo?.website">
                  Website: {{ item.displayInfo.website }}
                </div>
                <div *ngIf="item.displayInfo?.otherInfomation">
                  Other Infomation: {{ item.displayInfo.otherInfomation }}
                </div>
              </td>
              <td>
                <button *ngIf="!isSelected(item)" nz-button nzSize="small" (click)="onBtnConfirm(item)" nzType="primary" [nzLoading]="isLoadingVendor">Confirm</button>
                <button *ngIf="isSelected(item)" nz-button nzSize="small" nzDanger [nzLoading]="isLoadingVendor"
                  nz-popconfirm (nzOnConfirm)="onBtnRemove(item)" nzPopconfirmTitle="Are you sure to remove this relationship?">
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <!-- end view main-->
    </div>
  </div>
</div>





