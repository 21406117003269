import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { FinJobBreakdown } from '.';
import { ModuleDisplayFinJobCost } from '../fin-job-cost/module';
import { ModuleFinJobTxHistory } from '../fin-tx-list/module';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzButtonModule,
    ModuleDisplayFinJobCost,
    ModuleFinJobTxHistory,
  ],
  declarations: [FinJobBreakdown],
  exports: [FinJobBreakdown]
})
export class ModuleFinJobBreakdown {}