import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { ConstFin } from "@wearewarp/js-const-finance";
import moment from "moment";
import { FinUtil } from "@app/admin/fin/util";
import { ApiMethod } from "@app/enum";
import _ from 'underscore'
import { DialogService } from "@dialogs/dialog.service";
import { StatementAdditionalServiceInput } from "./additional-service";

const PaymentServices = {
  Denim: 'Denim',
}

@Component({
  selector: 'create-fin-statement-for-fixed-rate',
  templateUrl: './view.html',
  styleUrls: ['index.scss', '../../../status.scss']
})
export class CreateFinStatementForFixedRate extends BaseFormDialog1 {

  public fixedRateInfo: any = {};
  public listOfPaymentService = Object.values(PaymentServices);
  public highlightDays: any[] = [];
  public dataRouteForHighlightDay: any = {}
  public dataAdditionalServiceForHighlightDay: any = {}
  public dataOrderGroups: any[] = [];
  public totalAmount: number;

  @Input() onDone: (resp) => void;

  protected formGroupDeclaration: FormGroupDeclaration = {
    payable: {label: 'Payable', type: 'formGroup', childItem: {
      accountId: {label: 'Payee', required: true, placeHolder: 'Payee'},
      paymentServiceName: { label: 'Payment Service', required: true },
      invoiceFile: {label: 'Invoice File', type: 'uploadFile'},
    }},
    receivable: {label: 'Receivable', type: 'formGroup', childItem: {
      accountId: {label: 'Payer', required: true, placeHolder: 'Payer'},
      orderId: {label: 'Order', required: true },
    }},
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fetchFinAccountFixedRateList();
    this.autoSelectPaymentServiceIfNeed();
  }

  get isCreateNew(): boolean {
    return true;
  }

  get needUpdate(): boolean {
    if (!this.highlightDays?.length) return false;
    return super.needUpdate;
  }

  private autoSelectPaymentServiceIfNeed() {
    if (this.formInput && this.listOfPaymentService.length === 1) {
      this.setItemValue('payable.paymentServiceName', this.listOfPaymentService[0]);
    }
  }

  public isFetchingFinAccounts = false;
  public listFinAccounts: any[] = [];
  private fetchFinAccountFixedRateList() {
    let url = Const.APIURI_FINANCES_BATCH(`fin_accounts_fixed_rate?finType=${ConstFin.FinType.payable}`);
    this.isFetchingFinAccounts = true;
    this.api.GET(url).subscribe(
      resp => {
        this.listFinAccounts = resp.data.list_data;
        this.isFetchingFinAccounts = false;
      }, err => {
        this.showErr(err);
        this.isFetchingFinAccounts = false;
      }
    );
  }

  onChangeAccountId(accountId) {
    const finAccount = this.listFinAccounts.find(x => x.id == accountId);
    this.fixedRateInfo = finAccount?.fixedRateInfo ?? {};
    this.highlightDays = [];
    this.dataRouteForHighlightDay = {};
    this.dataOrderGroups = [];
    this.setItemValue('receivable.accountId', null);
    this.setItemValue('receivable.orderId', null);
    this.totalAmount = 0;
  }

  private updateTotalAmount() {
    let total = 0;
    if (this.fixedRateInfo?.type == 'perDay') {
      total += this.fixedRateInfo.rate * this.highlightDays.length;
    }
    for (let day of this.highlightDays) {
      const additionalServices = this.dataAdditionalServiceForHighlightDay[day] || [];
      for (let item of additionalServices) {
        total += item.rate;
      }
    }
    this.totalAmount = total;
  }

  formatMoney(value: number | string) {
    return InputHelper.formatMoney2(`${value || 0}`);
  }

  public getPaymentFixedRateTypeName(type) {
    switch (type) {
      case 'perDay':
        return 'per Day';
      case 'perWeek':
        return 'per Week';
      case 'perMonth':
        return 'per Month';
      default:
        return '';
    }
  }

  
  public isHighlight(date: Date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    return this.highlightDays.includes(dateString);
  }

  dateSelectChange(date: Date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    if (this.highlightDays.includes(dateString)) {
      this.highlightDays = this.highlightDays.filter(x => x != dateString);
    } else {
      this.highlightDays.push(dateString);
    }
    this.highlightDays = Utils.cloneObject(this.highlightDays);
    this.updateDataRouteHighLightDay();
    this.fetchDataOrderHighLightDay();
    this.updateTotalAmount();
  }

  private updateDataRouteHighLightDay() {
    const keys = Object.keys(this.dataRouteForHighlightDay);
    for (let key of keys) {
      if (!this.highlightDays.includes(key)) {
        delete this.dataRouteForHighlightDay[key];
      }
    }
    for (let date of this.highlightDays) {
      if (!this.dataRouteForHighlightDay[date]) {
        this.getListFinBatchByDate(date);
      }
    }
  }

  private getListFinBatchByDate(date: string) {
    const accountId = this.getItemValue('payable.accountId');
    const fromDate = `${date}T00:00:00.000Z`;
    const toDate = `${date}T23:59:59.999Z`;
    const condition = { accountId, fromDate, toDate };
    let params = { filter: JSON.stringify(condition) };
    let qs = new URLSearchParams(params).toString();
    const url = Const.APIURI_FINANCES_BATCH(`list_batch_for_carrier_fixed_rate?${qs}`);
    this.dataRouteForHighlightDay[date] = { isLoading: true }
    this.api.GET(url).subscribe(
      resp => {
        if (resp.data?.list_data?.length) {
          const arr = resp.data.list_data.map(item => {
            return {
              id: item.id,
              code: `AP-${item.code}`,
              jobCode: item.relatedBiz?.code,
              jobUrl: `${Const.routeAdminDispatchList}/${item.relatedBiz?.id}`,
              intermediateStatus: item.intermediateStatus,
            }
          });
          this.dataRouteForHighlightDay[date] = {
            isLoading: false,
            routes: arr
          }
        } else {
          this.dataRouteForHighlightDay[date] = { isLoading: false }
        }
      }, err => {
        this.dataRouteForHighlightDay[date] = { isLoading: false }
      }
    );
  }

  public isLoadingRefreshListOrder = false;
  private fetchDataOrderHighLightDay() {
    this.setItemValue('receivable.accountId', null);
    this.setItemValue('receivable.orderId', null);
    if (!this.highlightDays.length) {
      this.dataOrderGroups = [];
      return;
    }
    const params = {
      accountId: this.getItemValue('payable.accountId'),
      workingDays: this.highlightDays,
    }
    const url = Const.APIURI_FINANCES_BATCH(`list_order_for_carrier_fixed_rate`);
    this.isLoadingRefreshListOrder = true;
    this.api.POST(url, params).subscribe(
      resp => {
        const orders = resp.data?.list_data || [];
        let groupNames = _.uniq(orders.map(item => item.finAccount?.accountProfile.entityName)).filter(x => x);
        this.dataOrderGroups = _.sortBy(groupNames.map(g => {
          return {
            name: g,
            account: orders.find(it => it.finAccount?.accountProfile.entityName === g).finAccount,
            childrens: orders.filter(it => it.finAccount?.accountProfile.entityName === g),
          }
        }), "name");
        this.isLoadingRefreshListOrder = false;
      }, err => {
        this.showErr(err);
        this.dataOrderGroups = [];
        this.isLoadingRefreshListOrder = false;
      }
    );
  }

  protected buildUrl(method: ApiMethod): string {
    return `${Const.APIURI_FINANCES_STATEMENT('carrier-fixed-rate')}`;
  }

  protected getFormData_JSON(isCreateNew: boolean) {
    let json: any = super.getFormData_JSON(true);
    let paymentServiceInfo: any = {};
    if (json.payable.paymentServiceName === PaymentServices.Denim) {
      paymentServiceInfo = {
        name: PaymentServices.Denim,
      }
    }
    let arrAdditionalServices: any[] = [];
    for (let day of this.highlightDays) {
      const additionalServices = this.dataAdditionalServiceForHighlightDay[day] || [];
      for (let item of additionalServices) {
        arrAdditionalServices.push(item);
      }
    }
    json.payable = {
      ...(json.payable || {}),
      workingDays: this.highlightDays,
      paymentServiceInfo: paymentServiceInfo,
      additionalServices: arrAdditionalServices,
    }
    return json;
  }

  batchStatus(item) {
    return FinUtil.showStatusFinIntermediate(item.intermediateStatus);
  }

  get getTotalAmount() {
    if (!this.fixedRateInfo?.type) return 'N/A';
    if (this.fixedRateInfo?.type == 'perDay') {
      return this.formatMoney(this.totalAmount);
    }
    return 'N/A';
  }

  public get labelSelectFile(): string {
    let key = 'payable.invoiceFile';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : null;
  }

  protected onCreateSuccess(resp) {
    super.onCreateSuccess(resp);
    this.closeDialog();
    this.onDone(resp);
  }

  getDetailOrderLink(order) {
    return `${Const.routeAdminOrderList}?page=1&search=${order.code}`
  }

  getGrandTotal(order) {
    const amount = order?.amount ?? 0;
    return this.formatMoney(amount);
  }

  onBtnSelectOrder(order) {
    const finAccountId = order?.finAccount?.id;
    const orderId = order?.id;
    if (!orderId || !finAccountId) return;
    this.setItemValue('receivable.accountId', finAccountId);
    this.setItemValue('receivable.orderId', orderId);
  }

  public isSelectOrder(order) {
    const orderId = order.id;
    return this.getItemValue('receivable.orderId') === orderId;
  }

  onBtnSyncListOrder() {
    this.fetchDataOrderHighLightDay();
  }

  onBtnAdditionalServices(date: string) {
    DialogService.openFormDialog1(StatementAdditionalServiceInput, {
      nzComponentParams: {
        date: date,
        onOk: (data: {name: string, rate: number}) => {
          if (!this.dataAdditionalServiceForHighlightDay?.[date]) {
            this.dataAdditionalServiceForHighlightDay[date] = [{
              ...data,
              date: date,
              id: Utils.generateULID(),
            }];
          } else {
            this.dataAdditionalServiceForHighlightDay[date].push({
              ...data,
              date: date,
              id: Utils.generateULID(),
            });
          }
          this.updateTotalAmount();
        }
      },
      nzClassName: "modal",
    });
  }

  getAdditionalServceByDay(date) {
    return this.dataAdditionalServiceForHighlightDay?.[date] || [];
  }

  onBtnRemoveAdditionalService(item) {
    let message = `Remove additional service <b>${item.name}</b>?`;
    DialogService.confirmDeletion({
      message: message,
      txtBtnOk: 'Remove',
      fnOk: () => {
        const date = item.date;
        this.dataAdditionalServiceForHighlightDay[date] = this.dataAdditionalServiceForHighlightDay[date].filter(x => x.id != item.id);
        this.updateTotalAmount();
      }
    })
  }

}
