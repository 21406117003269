import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { FinBatch } from './comp';
import { ModuleBatchFinJob } from '../batch-fin-job/module';
import { FileToolModule } from '@app/admin/components/file/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzButtonModule,
    NzToolTipModule,
    ModuleBatchFinJob,
    FileToolModule
  ],
  declarations: [FinBatch],
  exports: [FinBatch]
})
export class ModuleFinBatch {
}