import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { FinReceivableBatchList } from './batch-list';
import { ModuleFinReceivableBatchListFilter } from './batch-list/filter/module';
import { FinReceivableBatchDetail } from './batch-detail';
import { ModuleFinBatch } from '../components/batch/module';
import { ModuleConfirmPod } from '@app/admin/shipments/confirm-pod/module';
import { ModuleFinStatement } from '../components/fin-statement/module';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NzTableModule,
    NzTabsModule,
    NzIconModule,
    NzSpinModule,
    NzTagModule,
    NzToolTipModule,
    NzCheckboxModule,
    ModuleFinReceivableBatchListFilter,
    ModuleFinBatch,
    ModuleConfirmPod,
    ModuleFinStatement,
  ],
  declarations: [FinReceivableBatchList, FinReceivableBatchDetail],
  exports: [FinReceivableBatchList, FinReceivableBatchDetail]
})
export class AccountsReceivableModule {
}