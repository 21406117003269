import { Component, Input } from '@angular/core';
import { BaseDialog } from '@dialogs/base-dlg';
import { DialogService } from '@dialogs/dialog.service';
import { CreateDenimJob } from '../denim/create-denim-job';
import { DenimJobCreateSuccessfully } from '../denim/success-dialog';

@Component({
  selector: '[statement-created-successfully]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss' ]
})
export class StatementCreateSuccessfully extends BaseDialog {

  @Input() model;

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }

  get statementCode() {
    if (!this.model?.code) return '';
    return `ST-${this.model.code}`;
  }

  get isPaymentServiceDenim() {
    return this.model?.paymentServiceInfo?.name === 'Denim';
  }

  onBtnCreateDenimJob() {
    if (!this.model?.id) return;
    this.closeDialog();
    DialogService.openFormDialog1(CreateDenimJob, {
      nzComponentParams: {
        statementId: this.model.id,
        onSuccess: (denimJob) => {
          DialogService.openDialog(DenimJobCreateSuccessfully, {
            nzWidth: 400,
            nzClosable: false,
            nzMaskClosable: false,
            nzComponentParams: {
              model: denimJob
            },
          });
        }
      },
      nzClassName: "modal-xxl",
    });
  }
}