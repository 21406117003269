import { Component, Input, OnInit } from '@angular/core';
import { InputHelper } from '@services/input-helper';
import { MasterData } from '@services/master.data';
import { Cost, ServiceOptionCost } from '@wearewarp/types/data-model';
import { FinDisplayCostItem, FinUtil } from '../../util';

export interface FinCost {
  transitCost?: Cost,
  fuelCost?: Cost,
  volumeDiscount?: Cost,
  serviceOptions?: Array<Cost>,
}

@Component({
  selector: '[display-fin-job-cost]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class DisplayFinJobCost implements OnInit {
  @Input() finType: string;
  @Input() data: FinCost;

  displayItems: FinDisplayCostItem[] = []

  get isAR(): boolean { return this.finType == 'receivable' }
  get serviceOptions(): ServiceOptionCost[] { return this.data.serviceOptions ?? [] }

  ngOnInit(): void {
    this.displayItems = this.buildDisplayData();
  }

  private buildDisplayData() {
    const arr = [FinUtil.buildDisplayTransitCost(this.data.transitCost)];
    if (this.data.volumeDiscount) {
      arr.push(FinUtil.buildDisplayVolumeDiscount(this.data.volumeDiscount));
    }
    arr.push(FinUtil.buildDisplayFuelCost(this.data.fuelCost));
    const arrOps = FinUtil.buildDisplayServiceOption(this.data.serviceOptions);
    return [...arr, ...arrOps];
  }



  // formatMoney(amt: number | string) {
  //   return InputHelper.formatMoney2(`${amt || 0}`);
  // }

  // getServiceOptionMoneyValue(serviceOptionItem) {
  //   let text = InputHelper.formatMoney2(`${serviceOptionItem.total}`);
  //   if (MasterData.isServiceOptionTypeNegative(serviceOptionItem._id)) {
  //     return `-${text}`;
  //   }
  //   return text;
  // }

  // getServiceOptionName(item): string {
  //   return MasterData.getServiceOptionName(item);
  // }
}