import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from '@app/admin/base/detail';
import { ApiMethod } from '@app/enum';
import { Const } from '@const/Const';
import { ApiService } from '@services/api.service';
import { Utils } from '@services/utils';
import { ConstFin } from '@wearewarp/js-const-finance';
import { ResponseAdminFinBatchDetail } from '@wearewarp/types/rest-api/admin/fin';
import { Subscription } from 'rxjs';
import { FinService } from '../../fin-service';

@Component({
  selector: 'fin-payable-batch-detail',
  templateUrl: './index.html',
  styleUrls: ['../../../detail.scss', './index.scss']
})
export class FinPayableBatchDetail extends BaseDetail<ResponseAdminFinBatchDetail> {
  get routeId() { return this.model.relatedBiz.id }
  public saleRepEmails: string[] = [];    // TODO: need to call API to fetch saleRepEmails for a route

  ngOnInit(): void {
    super.ngOnInit();
    this.getRelatedBatches();
  }

  private onRefreshBatchDetailSubscription: Subscription;
  constructor(protected activatedRoute: ActivatedRoute, private finService: FinService) {
    super(activatedRoute);
    this.onRefreshBatchDetailSubscription = this.finService.onRefreshAPBatchDetail.subscribe(() => {
      this.onBtnRefresh();
      this.getRelatedBatches();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.onRefreshBatchDetailSubscription?.unsubscribe();
  }

  protected buildUrl(method: ApiMethod): string {
    return Const.APIURI_FINANCES_BATCH(`${this.id}?finType=${ConstFin.FinType.payable}`);
  }

  showBatchCode(item) {
    return `AP-${item.code}`;
  }

  public getRelatedBatchesLoading = false;
  public getRelatedBatchesError;
  public relatedBatches: ResponseAdminFinBatchDetail[] = [];

  private getRelatedBatches() {
    this.getRelatedBatchesLoading = true;
    const url = Const.APIURI_FINANCES_BATCH(`${this.id}/related_batches`);
    ApiService.instance.GET(url).subscribe(
      resp => {
        this.getRelatedBatchesLoading = false
        this.relatedBatches = resp.data.list_data;
      }, err => {
        this.getRelatedBatchesError = Utils.getErrorString(err);
        this.getRelatedBatchesLoading = false;
      }
    );
  }
}