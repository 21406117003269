import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'statement-additional-service-input',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class StatementAdditionalServiceInput extends BaseFormDialog1 {

    public isLoading = false;

    @Input() date: string;
    @Input() onOk: (data) => void;

    protected formGroupDeclaration: FormGroupDeclaration = {
      name: { label: "Service Name", required: true },
      rate: {label: 'Rate', type: 'number', required: true, placeHolder: `$0.00`, getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value)},
    };

    onBtnSave() {
      if (!this.needUpdate) return;
      const data = this.getFormData_JSON(true);
      this.onOk(data);
      this.closeDialog();
    }

    onInputKeyPress(event, key) {
      switch (key) {
        case 'rate':
          return InputHelper.handleInputKeyPressMoney(event);
        default:
          return super.onInputKeyPress(event, key);
      }
    }

    onInputChanged(event, key) {
      switch (key) {
        case 'rate':
          return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
        default:
          return super.onInputChanged(event, key);
      }
    }
    
  }
