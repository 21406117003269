<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Create Statement</div>
  </div>
</ng-template>
<div>
  <div>Please choose a type:</div>
  <div class="dlg-create-statement-body">
    <button *ngFor="let item of createShipmentStatementTypes" nz-button (click)="onBtnSelectEditMode(item.id)" class="bottom20">
      <span class="left20">{{ item.name }}</span>
    </button>
  </div>
</div>