import { BaseList } from "@base/list";
import { Component, ViewChild } from "@angular/core";
import { Const } from "@app/const/Const";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";
@Component({
  selector: "truck-list",
  templateUrl: "./list.html",
  styleUrls: ["./truck-list.scss", "../../list.scss", "../../../app.scss"],
})
export class DatTruckList extends BaseList {
  sortOrderAge: string;
  sortOrderRate: string;
  sortOrderAvailable: string;
  sortOrderTrip: string;
  sortOrderOrigin: string;
  sortOrderDho: string;
  sortOrderDestination: string;
  sortOrderDhd: string;

  equipmentTypes: any = [];

  loadTypes = [
    { value: "BOTH", label: "Full & Partial" },
    { value: "FULL", label: "Full" },
    { value: "PARTIAL", label: "Partial" },
  ];

  expandSet = new Set<number>();

  protected filter: any;

  constructor() {
    super();
  }

  protected getApiUrl(): string {
    return Const.APIURI_THIRD_PARTY_POST + "/search/truck";
  }

  protected getData() {
    if (!this.filter) return;
    let apiUrl = this.getApiUrl();
    if (!apiUrl) {
      Log.e(`[${this.TAG}] getData but apiUrl is empty`);
      return;
    }
    const params = {
      filter: this.filter,
      ...this.prepareParamGetList()
    };
    this.isLoading = true;
    this.api.POST(this.api.buildUrl(apiUrl, this.apiVersion), params).subscribe(
      (resp) => {
        Log.d("get list data done: ", resp);
        this.getDataDone(resp);
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      },
      () => {
        // Complete callback
        this.isLoading = false;
      }
    );
  }

  onSearch(filter) {
    this.filter = filter || null;
    this.getData();
  }

  // protected handleNavigationEnd(url: string, prevQueryParam: any) {
  //   super.handleNavigationEnd(url, prevQueryParam);
  //   console.log("handleNavigationEnd", url, prevQueryParam);
  //   // this.pageIndex = Utils.toNumber(this.queryParams.page, 1);
  //   // this.getData();
  // }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEquipmentTypes();
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  async getEquipmentTypes() {
    this.api.GET(`${Const.APIURI_THIRD_PARTY_POST}/list-equipments`).subscribe(
      (resp) => {
        this.equipmentTypes = resp.data?.list_data || [];
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

  getAddress(data) {
    const place = data?.place || data;
    if (place.open) {
      return "Anywhere";
    }
    if (place.city && place.stateProv) {
      return `${place.city}, ${place.stateProv}`;
    }
    if (place.area) {
      if (place.area.zones) {
        return place.area.zones.join(", ");
      }
      if (place.area.states) {
        return place.area.states.join(", ");
      }
    }

    return "N/A";
  }

  getPosterState(data) {
    return data.state || data.carrierHomeState || "N/A";
  }

  getEventDuration(evenTime) {
    const now = Date.now();
    const duration = now - new Date(evenTime).getTime();
    return DateUtil.displayDuration(duration, { skipSecond: true });
  }

  getDate(data) {
    return DateUtil.formatDate(data, "MM/DD/YYYY");
  }

  getEquipment(type: string) {
    const equipment = this.equipmentTypes.find((t) => t.value === type);
    return equipment ? `${type} | ${equipment.label}` : type;
  }

  getCapacity(capacity: any) {
    return `${capacity.availableLengthFeet || "-"} ft | ${
      capacity.availableWeightPounds || "-"
    } lbs | ${this.getLoadType(capacity.fullPartial)}`;
  }

  getLoadType(type: string) {
    return this.loadTypes.find((t) => t.value === type)?.label || type;
  }

  getPhoneOnly(phone: string = "") {
    if (phone.indexOf("ext.") > -1) {
      return phone.split("ext.")[0].trim();
    }
    return phone.trim();
  }

  getPhoneExt(phone: string = "") {
    if (phone.indexOf("ext.") > -1) {
      return phone.split("ext.")[1].trim();
    }
    return "";
  }

  displayPhone(phone: string = "") {
    const _phone = super.displayPhone(this.getPhoneOnly(phone));
    return _phone;
  }
}
