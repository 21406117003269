<div nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" [(nzVisible)]="visible">
    <nz-input-group [nzSuffix]="suffixIconSearch" style="width: 600px;" nzSize="small">
        <input type="text" nz-input placeholder="Search Bill By WARP ID, Code..." [formControl]="searchControl"/>
    </nz-input-group>
    <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
    </ng-template>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
    <div #backgroupDropdown class="backgroup-dropdown-search-bill" style="min-height: 200px;">
        <div *ngIf="isLoading">
            <div class="nodata-loading"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </div>
        <div *ngIf="!isLoading">
            <div *ngIf="!listOfData?.length" class="nodata-loading">
                <i nz-icon nzTheme="outline" nzType="search"></i> No data
            </div>
            <div *ngIf="listOfData?.length">
                <div *ngFor="let item of listOfData" class="bill-item" (click)="onBtnOpenBill(item)">
                    <span>{{ item.displayInfo?.billNo }}</span> |
                    <span>{{ item.displayInfo?.amount }}</span> |
                    <span [innerHTML]="item.displayInfo?.searchResultHtml"></span>
                </div>
            </div>
        </div>
    </div>
</nz-dropdown-menu>

