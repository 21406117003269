<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Detail Statement {{ displayInfo.code ?? '' }}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div class="bottom5 flex-space-between">
  <div>
    <div>Payment Service: <b>{{ displayInfo.paymentServiceInfo?.name || "N/A" }}</b></div>
    <div *ngIf="isPaymentServiceDenim" class="top5">
      <div>Reference: {{ displayInfo.paymentServiceInfo?.refs?.join(', ') || '' }}</div>
      <div *ngIf="!canCreateDenimJob">Denim job at <a href="{{displayInfo.paymentServiceInfo?.denimJobLink}}" target="_blank">here</a></div>
    </div>
  </div>
  <div *ngIf="canCreateDenimJob">
    <button nz-button nzType="primary" (click)="onBtnCheckDenimJobWithRef()" class="right10">Check Denim Job with Ref</button>
    <button nz-button nzType="primary" (click)="onBtnCreateDenimJob()">Create Denim Job</button>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="12">
    <div class="text-center f15 medium bottom10">Receivables</div>
    <div *ngIf="!displayInfo.receivableGroups?.length">
      <div class="nodata-statement">No Data</div>
    </div>
    <div *ngFor="let group of displayInfo.receivableGroups || []">
      <div class="flex medium f14 bottom10">
        <div>Customer: </div>
        <div class="left5">
          <a target="_blank" class="link" [routerLink]="routerLinkAccount(group.account, 'AR')">{{ group.name }}</a>
        </div>
      </div>
      <ng-container *ngFor="let child of group.childrens || []">
        <div class="box-child">
          <div class="batch" *ngIf="child.batch?.id">
            <div class="label">Batch</div>
            <a [routerLink]="getBatchRouterLink(child.batch, 'AR')" target="_blank">{{ showBatchCode(child.batch, 'AR')}}</a>
          </div>
          <div class="biz-entity">
            <div class="label">Order</div>
            <a target="_blank" [routerLink]="routerLinkBizEntity(child.relatedBiz, 'AR')">{{child.relatedBiz.code}}</a>
          </div>
          <div *ngIf="child.invoiceFiles?.length" class="biz-entity">
            <div class="label">Invoice</div>
            <div>
              <div *ngFor="let invoice of child.invoiceFiles">
                <a (click)="downloadAttachedFile(invoice)">
                  <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                    class="right10"></i>
                  <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                    class="right10"></i>{{ invoice.name ?? 'Download Invoice' }}
                </a>
              </div>
            </div>
          </div>
          <div class="cost">
            <div class="label">Cost</div>
            <div class="flex1">
              <div class="flex-space-between">
                <div>Amount:</div>
                <div>${{ child.cost?.amt ?? 0}}</div>
              </div>
              <div>Services:</div>
              <div *ngFor="let service of child.cost?.services || []" class="flex-space-between">
                <div>{{ service?.name }}</div>
                <div>{{ service?.negative ? '-' : '' }} $ {{ service?.total ?? 0}}</div>
              </div>
              <div class="flex-space-between">
                <div>GrandTotal:</div>
                <div>${{ child.cost?.grandTotal ?? 0}}</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <nz-divider class="divider-info" nzType="vertical"></nz-divider>
  <div nz-col nzSpan="11">
    <div class="text-center f15 medium bottom10">Payables</div>
    <ng-container *ngIf="!displayInfo.isFixedRate">
      <div *ngIf="!displayInfo.payableGroups?.length">
        <div class="nodata-statement">No Data</div>
      </div>
      <div *ngFor="let group of displayInfo.payableGroups || []">
        <div class="flex medium f14 bottom10">
          <div>Carrier: </div>
          <div class="left5">
            <a target="_blank" class="link" [routerLink]="routerLinkAccount(group.account, 'AP')">{{ group.name }}</a>
          </div>
        </div>
        <ng-container *ngFor="let child of group.childrens || []">
          <div class="box-child">
            <div class="batch">
              <div class="label">Batch</div>
              <a [routerLink]="getBatchRouterLink(child.batch, 'AP')" target="_blank">{{ showBatchCode(child.batch, 'AP')}}</a>
            </div>
            <div class="biz-entity">
              <div class="label">Route</div>
              <a target="_blank" [routerLink]="routerLinkBizEntity(child.relatedBiz, 'AP')">{{child.relatedBiz.code}}</a>
            </div>
            <div *ngIf="child?.invoiceFiles?.length" class="biz-entity">
              <div class="label">Invoice</div>
              <div>
                <div *ngFor="let invoice of child.invoiceFiles">
                  <a (click)="downloadAttachedFile(invoice)">
                    <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                      class="right10"></i>
                    <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                      class="right10"></i>{{ invoice.name ?? 'Download Invoice' }}
                  </a>
                </div>
              </div>
            </div>
            <div class="cost">
              <div class="label">Cost</div>
              <div class="flex1">
                <div class="flex-space-between">
                  <div>Amount:</div>
                  <div>${{ child.cost?.amt ?? 0}}</div>
                </div>
                <div>Services:</div>
                <div *ngFor="let service of child.cost?.services || []" class="flex-space-between">
                  <div>{{ service?.name }}</div>
                  <div>{{ service?.negative ? '-' : '' }}${{ service?.total ?? 0}}</div>
                </div>
                <div class="flex-space-between">
                  <div>GrandTotal:</div>
                  <div>${{ child.cost?.grandTotal ?? 0}}</div>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let finJob of child?.finJobs ?? []">
              <div *ngIf="finJob?.quickbooksBillId" class="cost">
                <div class="label">Quickbooks</div>
                <div class="flex1">
                  <div class="flex-space-between">
                    <div>Bill no.</div>
                    <div><a [href]="getQBBillLink(finJob?.quickbooksBillId)" target="_blank" nz-tooltip="Go to Quickbooks">{{ finJob?.quickbooksBillNo ?? 'here' }}</a></div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="displayInfo.isFixedRate && displayInfo.fixedRateInfo?.account">
      <div class="flex medium f14 bottom10">
        <div>Carrier: </div>
        <div class="left5">
          <a target="_blank" class="link" [routerLink]="routerLinkAccount(displayInfo.fixedRateInfo.account, 'AP')">{{ displayInfo.fixedRateInfo.account.name }}</a>
        </div>
      </div>
      <div class="box-child">
        <div class="biz-entity">
          <div>Payment Fixed Rate:</div>
          <div class="bold left5">{{ displayInfo.fixedRateInfo.paymentFixedRate }}</div>
        </div>
        <div class="biz-entity">
          Working Days({{displayInfo.fixedRateInfo.numOfWorkingDays}} days) : {{ displayInfo.fixedRateInfo?.workingDays?.join(', ') || 'N/A' }}
        </div>
        <div *ngIf="displayInfo.fixedRateInfo.invoiceFiles?.length" class="biz-entity bottom5">
          <div class="label">Invoice</div>
          <div>
            <div *ngFor="let invoice of displayInfo.fixedRateInfo.invoiceFiles">
              <a (click)="downloadAttachedFile(invoice)">
                <i *ngIf="!invoice.isDownloading" nz-icon nzType="file-pdf" nzTheme="outline"
                  class="right10"></i>
                <i *ngIf="invoice.isDownloading" nz-icon nzType="loading" nzTheme="outline"
                  class="right10"></i>{{ invoice.name ?? 'Download Invoice' }}
              </a>
            </div>
          </div>
        </div>
        <div class="cost">
          <div class="label">Cost</div>
          <div class="flex1">
            <div class="flex-space-between">
              <div>Amount:</div>
              <div>${{ displayInfo.fixedRateInfo.cost?.amt ?? 0}}</div>
            </div>
            <div>Services:</div>
            <div *ngFor="let service of displayInfo.fixedRateInfo.cost?.services || []" class="flex-space-between">
              <div>{{ service?.name }}</div>
              <div>{{ service?.negative ? '-' : '' }}${{ service?.total ?? 0}}</div>
            </div>
            <div class="flex-space-between">
              <div>GrandTotal:</div>
              <div>${{ displayInfo.fixedRateInfo.cost?.grandTotal ?? 0}}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div>
  <div class="bottom5">
    Proof Of Payments:
    <span *ngIf="!displayInfo.proofOfPaymentFiles?.length" class="left5">N/A</span>
  </div>
  <div *ngIf="displayInfo.proofOfPaymentFiles?.length" class="list-images-upload">
    <div *ngFor="let image of displayInfo.proofOfPaymentFiles">
      <img *ngIf="image" (click)="viewImageItem(image.imgUrl)" class="attached-image" [src]="image.imgUrl" alt="">
    </div>
  </div>
</div>
<div style="height: 60px;"></div>
