import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { FinJobTxHistory } from '.';
import { ModuleFinTxDetail } from '../fin-tx-detail/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzIconModule,
    NzTableModule,
    ModuleFinTxDetail,
  ],
  declarations: [FinJobTxHistory],
  exports: [FinJobTxHistory]
})
export class ModuleFinJobTxHistory {}